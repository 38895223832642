import React, { useRef, useCallback, useContext, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import Color from 'color'
import gsap from 'gsap'
import lerp from 'lerp'
import detectIt from 'detect-it'
import get from 'lodash/get'
import ResponsiveImage from '../../ResponsiveImage'
import { SmoothScrollContext, useScrollListener } from '../../useSmoothScroll'
import theme, { vw, span } from '../../../style/theme'
import useWindowResize from '../../../hooks/useWindowResize'
import useThrottle from '../../../hooks/useThrottle'
import RichText from '../../RichText'
import { getForegroundColorFromBackgroundColor } from '../../../style/colorHelper'
import Pagination from './Pagination'
import Link from '../../Link'
import { useDarkSlice } from '../../HeaderContextProvider'
import SplitWordsAnimation from '../../SplitWordsAnimation'
import { resolveLink } from '../../../helpers/resolveLink'
import size from './size'
import Wedge from '../../Wedge'
import ArrowUpIcon from '../../../images/ArrowUpIcon'
import { clipPathShapesSupported } from '../../../helpers/supports'

function easeOutQuad (x) {
  return 1 - (1 - x) * (1 - x)
}

const Slide = ({ slide, index, zIndex, slides, last, delay = 0.1 }) => {
  const { color, title, image, copy } = slide
  const {
    showcase_title: showcaseTitle,
    showcase_copy: showcaseCopy,
    showcase_cta_label: showcaseCta,
    showcase_link: showcaseLink
  } = slide
  const classes = useStyles({ backgroundColor: color, color: getForegroundColorFromBackgroundColor(color), zIndex, index })
  const ref = useRef()
  const imageRef = useRef()
  const titleRef = useRef()
  const slideRef = useRef()
  const clipAreaRef = useRef()

  const colorRef = useDarkSlice(Color(color).isDark())

  const scrollContext = useContext(SmoothScrollContext)
  const slideSizeRef = useRef({})

  const onScrollCallback = useCallback((offset) => {
    if (!slideRef.current || !clipPathShapesSupported()) return

    const top = index * slideSizeRef.current.height
    const bottom = top + slideSizeRef.current.height

    const isTouchDevice = detectIt.primaryInput === 'touch'

    if (!isTouchDevice) {
      slideRef.current.style.position = 'absolute'
      slideRef.current.style.transform = `translate3d(0,${offset.y}px,0)`
    } else {
      slideRef.current.style.position = 'fixed'
    }

    if (!last) {
      var percent = Math.max(0, Math.max(0, Math.min(1, (offset.y - top) / (bottom - top))) - delay) / (delay ? 1 - delay : 1)
      const leftEdge = easeOutQuad((1 - percent)) * 100
      var ease = isTouchDevice ? 1.1 : 1.2
      const rightEdge = (easeOutQuad(1 - (percent * ease))) * 100
      clipAreaRef.current.style.clipPath = `polygon(0 0, 100% 0, 100% ${rightEdge}%, 0 ${leftEdge}%)`
    }

    const y = -top + offset.y
    imageRef.current.querySelector('img').style.transform = `translate3d(0,${y * -0.1}px,0)`
    titleRef.current.style.transform = `translate3d(0,${y * -0.05}px,0)`
  }, [last])

  useWindowResize(useThrottle((event) => {
    if (slideRef.current) {
      const { width, height } = slideRef.current.getBoundingClientRect()
      slideSizeRef.current = {
        height,
        width,
        offsetTop: slideRef.current.offsetTop
      }
      onScrollCallback({ y: scrollContext.current.getPageYOffset() })
    }
  }, 500))

  useScrollListener(onScrollCallback)

  const currentY = useRef()
  useEffect(() => {
    if (detectIt.primaryInput === 'touch' && clipPathShapesSupported()) {
      const tick = () => {
        if (!currentY.current) {
          currentY.current = window.pageYOffset
        } else {
          currentY.current = lerp(currentY.current, window.pageYOffset, 0.15)
        }
        onScrollCallback({ y: currentY.current })
      }
      gsap.ticker.add(tick)
      return () => {
        gsap.ticker.remove(tick)
      }
    }
  }, [])

  useEffect(() => {
    if (slideRef.current && !clipPathShapesSupported()) {
      slideRef.current.style.position = 'static'
      slideRef.current.style.height = '100vh'
      colorRef.current.style.height = '100vh'
      colorRef.current.style.top = `${100 * index}vh`
    }
  }, [])

  const link = useMemo(() => ({ ...resolveLink(get(showcaseLink, [0])), text: '' }), [showcaseLink])

  return (
    <>
      <div className={classes.colorTrigger} ref={colorRef} />
      <div className={classes.slide} ref={slideRef}>
        <div className={classes.clipArea} ref={clipAreaRef}>
          <div ref={ref} className={classes.innerContent}>
            <div className={classes.content}>
              <div ref={titleRef}>
                {index === 0 ? <SplitWordsAnimation><h1 className={classes.title}>{title}</h1></SplitWordsAnimation> : <h2 className={classes.title}>{title}</h2>}
                <RichText content={copy.text} className={classes.copy} />
              </div>
              <div className={classes.elevatedArrow}>
                <ArrowUpIcon className={classes.arrow} />
              </div>
              <Pagination slides={slides} color={color} scrollContext={scrollContext} />
            </div>
            {/* We have a mobile link here as nothing in the clip path can be positioned absolute or relative on mobile otherwise it will receive clicks
            even when it is clicked */}
            <Link link={link} nonLinkTag='div' className={classes.mobileLink} />
            <div className={classes.imagePanel}>
              <Link link={link} nonLinkTag='div' className={classes.link}>
                <ResponsiveImage {...image} className={classes.image} ref={imageRef}>
                  <Wedge
                    className={classes.wedge}
                    title={showcaseTitle}
                    copy={showcaseCopy}
                    ctaText={showcaseCta}
                    backgroundColor={color}
                    hideCopyOnMobile
                  />
                </ResponsiveImage>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = createUseStyles({
  colorTrigger: {
    position: 'absolute',
    height: `${size.mobileHeight * 100}vh`,
    pointerEvents: 'none',
    top: ({ index }) => `${size.mobileHeight * 100 * index}vh`,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      top: ({ index }) => `${size.height * 100 * index}vh`,
      height: `${size.height * 100}vh`
    }
  },
  slide: {
    height: `${size.mobileHeight * 100}vh`,
    width: '100%',
    top: 0,
    zIndex: ({ zIndex }) => zIndex,
    position: 'absolute',
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
      height: `${size.height * 100}vh`
    }
  },
  clipArea: {
    height: '100vh',
    width: '100%',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    clipPath: ({ index }) => 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    color: ({ color }) => color,
    overflow: 'hidden',
    position: 'fixed',
    pointerEvents: 'all'
  },
  innerContent: {
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  panel: {
    height: '50vh',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '100vh',
      width: '50%'
    }
  },
  contentPadding: {
    ...theme.margins('padding')
  },
  elevatedArrow: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      bottom: 75,
      left: theme.getMargin('md'),

      '& svg': {
        display: 'block',
        height: 60,
        width: 8,
        fill: ({ color }) => color
      }
    }
  },
  content: {
    composes: ['$panel', '$contentPadding'],
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '55vh',
    paddingTop: 95,
    [theme.breakpoints.up('md')]: {
      height: '100vh',
      display: 'block',
      paddingTop: 180,
      position: 'relative'
    }
  },
  mobileLink: {
    height: '45vh',
    width: '100%',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  link: {
    color: 'inherit'
  },
  imagePanel: {
    composes: ['$panel'],
    height: '45vh',
    color: 'currentColor',
    display: 'block',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
      pointerEvents: 'all',
      height: '100vh',
      position: 'relative'
    }
  },
  image: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    '& img': {
      ...size.imageOffset
    }
  },
  title: {
    textTransform: 'uppercase',
    extend: theme.typography.h1,
    marginBottom: vw(20),
    letterSpacing: '-0.02em',
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(38, 'lg')
    }
  },
  copy: {
    '& > *': {
      extend: theme.typography.bodyPlus,
      margin: 0,
      lineHeight: 1.5,
      maxWidth: '85%',
      [theme.breakpoints.up('md')]: {
        maxWidth: span(6, 'md')
      }
    }
  },
  button: {
    display: 'inline-flex',
    marginTop: vw(20),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(38, 'lg')
    }
  },
  wedge: {
    width: '50%',
    [theme.breakpoints.up('md')]: {
      width: span(6, 'md')
    }
  }
}, { name: 'Slide' })

export default Slide

import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import map from 'lodash/map'
import cn from 'classnames'
import ResponsiveImage from '../../ResponsiveImage'

export default ({ slice }) => {
  const { layout, alignment, images } = slice
  const classes = useStyles({ alignment })

  if (layout === 'full_width') {
    return (
      <section className={classes.section}>
        <div className={classes.fullWidthImage}>
          {map(images, image => <ResponsiveImage {...image} aspect={1.77} captionAlignment='left' />)}
        </div>
      </section>
    )
  }

  if (layout === 'right_side') {
    return (
      <section className={classes.rightSideSection}>
        <div className={classes.fullWidthImage}>
          {map(images, image => <ResponsiveImage {...image} isContain aspect={1.77} captionAlignment='left' />)}
        </div>
      </section>
    )
  }

  if (layout === 'two_columns_right_side') {
    return (
      <section className={cn(classes.rightSideSection, classes.twoColumnRightSide)}>
        <div className={classes.grid}>
          {map(images, image => <ResponsiveImage {...image} aspect={1.22} captionAlignment='left' />)}
        </div>
      </section>
    )
  }

  return (
    <section className={classes.section}>
      <div className={classes.grid}>
        {map(images, image => <ResponsiveImage {...image} aspect={1.22} captionAlignment='left' />)}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    margin: [0, theme.getMargin()],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md')]
    }
  },

  rightSideSection: {
    width: '100%',
    padding: '0 20px',
    [theme.breakpoints.up('md')]: {
      width: vw(1000, 'lg'),
      padding: '0 50px',
      marginLeft: 'auto',
      marginRight: theme.getMargin('md'),
      '&>div': {
        marginBottom: vw(50, 'lg')
      }
    }
  },

  twoColumnRightSide: {
    [theme.breakpoints.up('md')]: {
      marginTop: vw(0)
    },
    '& div': {
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(0, 'lg')
      }
    }
  },

  grid: {
    justifyContent: ({ alignment }) => alignment === 'right' ? 'flex-end' : 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    margin: [0, -16],
    '& > *': {
      margin: [0, 16, vw(40)],
      width: 'calc(100% - 32px)',
      [theme.breakpoints.up('md')]: {
        margin: [0, 16, vw(78, 'lg')],
        width: 'calc(50% - 32px)'
      }
    }
  },
  fullWidthImage: {
    margin: [0, 0, vw(20), 0],
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, vw(60, 'lg'), 0]
    }
  },

  imageCaption: {
    position: 'relative',
    fontSize: '15px',
    color: '#9999A7'
  }
}, 'PostImages')

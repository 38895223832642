import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import Link from '../Link'
import cn from 'classnames'
import ResponsiveImage from '../ResponsiveImage'
import { resolveLink } from '../../helpers/resolveLink'
import theme, { vw, span } from '../../style/theme'
import { formatDate } from '../../utils/format'
import PostCategory from '../Slices/Post/PostCategory'

export default (props) => {
  const {
    slug,
    title,
    link,
    post_date: postDate,
    preview_image: previewImage,
    preview_snippet: previewSnippet,
    type
  } = props
  const classes = useStyles({})

  const resolvedLink = useMemo(() => resolveLink(link), [link])

  return (
    <li key={slug} className={cn(classes.postListItem, type === 'related' ? 'startAlign' : 'centerAlign')}>
      <Link link={resolvedLink} className={classes.postListItemImage} nonLinkTag='div'>
        <ResponsiveImage {...previewImage} aspect={1.4} />
      </Link>
      <div className={cn(classes.postInformation, type === 'related' ? 'paddingFromTop' : 'noPadding')}>
        <Link link={resolvedLink} className={classes.postDescription} nonLinkTag='div'>
          <span className={classes.title}>{title}</span>
          <span className={cn(type === 'related' ? classes.relatedPostDescription : classes.description)}>{previewSnippet}</span>
        </Link>
        <div className={cn(classes.postMeta, type === 'related' ? 'onTop' : 'onBottom')}>
          <span className={classes.postDate}>{formatDate(postDate)}</span>
          <PostCategory page={props} className={classes.metaLinks} />
        </div>
      </div>
    </li>
  )
}

const useStyles = createUseStyles({
  postListItem: {
    display: 'flex',
    flexDirection: 'column',
    '&:last-of-type': {
      border: 'none'
    },
    '&:not(last-child)': {
      [theme.breakpoints.up('md')]: {
        paddingBottom: vw(15, 'md'),
        marginBottom: vw(15, 'md'),
        borderBottom: [1, 'solid', theme.colors.grey],
        position: 'relative'
      }
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      '&.startAlign': {
        alignItems: 'flex -start'
      },
      '&.centerAlign': {
        alignItems: 'center'
      }
    }
  },
  postListItemImage: {
    marginBottom: vw(10),
    [theme.breakpoints.up('md')]: {
      width: vw(150, 'md'),
      flexShrink: 0,
      marginRight: vw(25, 'md'),
      marginBottom: 0
    }
  },
  postInformation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&.paddingFromTop': {
      paddingTop: vw(30, 'lg')
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: span(6, 'md')
    }
  },
  postMeta: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      '&.onBottom': {
        flexDirection: 'row',
        position: 'absolute',
        bottom: vw(15, 'md')
      },
      '&.onTop': {
        flexDirection: 'row',
        position: 'absolute',
        top: vw(2, 'md')
      }
    }
  },
  postDate: {
    extend: theme.typography.bodyTiny
  },
  metaLinks: {
    marginBottom: 5,
    extend: theme.typography.bodyTiny,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginLeft: 20
    }
  },
  postDescription: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: 'inherit',
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  title: {
    extend: theme.typography.bodyPlus,
    fontWeight: theme.typography.fontWeight.bold,
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(10, 'md')
    }
  },
  description: {
    extend: theme.typography.bodyTiny,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  relatedPostDescription: {
    extend: theme.typography.bodySmall,
    [theme.breakpoints.up('md')]: {
    }
  }
}, { name: 'PostTile' })

import React, { useMemo, useCallback, useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import find from 'lodash/find'
import theme, { vw } from '../style/theme'
import useOnClickOutside from '../hooks/useOnClickOutside'
import gsap from 'gsap'

const DropDownItem = ({ value, text, selected, onSelected, classNames = {}, selectedIcon }) => {
  const classes = useStyles()
  const onOptionSelected = useCallback(() => {
    onSelected(value)
  }, [value])

  return (
    <li className={cn(classes.item, classNames.item, { selected })} onClick={onOptionSelected}>
      {text}
    </li>
  )
}

export default ({ className, classNames = {}, options, value, onChange, placeholderText, dropdownAlignment = 'left', selectedIcon }) => {
  const classes = useStyles()
  const [open, setOpen] = useState()
  const dropDownRef = useRef()
  const ref = useOnClickOutside(useCallback(() => {
    setOpen(false)
  }))
  const toggleOpen = useCallback(() => {
    setOpen(value => !value)
  })
  const selectedText = useMemo(() => {
    return get(find(options, o => o.value === value), ['text'], placeholderText)
  }, [value])

  const onItemSelected = useCallback(value => {
    onChange({ target: { value } })
  }, [onChange])

  if (isEmpty(options)) return null

  useEffect(() => {
    if (dropDownRef.current) {
      gsap.to(dropDownRef.current, { height: open ? 'auto' : 0, duration: 0.15 })
    }
  }, [open])

  return (
    <div ref={ref} className={cn(classes.container, className)}>
      <div className={cn(classes.dropdown, classNames.dropdown)} onClick={toggleOpen}>
        <span className={cn(classes.value, classNames.value, { open })}>
          <span className={classes.text}>{selectedText}</span>
          <svg viewBox='0 0 8 10' fill='none' className={cn(classes.arrow, { open })}>
            <path d='M3.64645 9.03383C3.84171 9.22909 4.15829 9.22909 4.35355 9.03383L7.53553 5.85185C7.7308 5.65658 7.7308 5.34 7.53553 5.14474C7.34027 4.94948 7.02369 4.94948 6.82843 5.14474L4 7.97317L1.17157 5.14474C0.976311 4.94948 0.659728 4.94948 0.464466 5.14474C0.269204 5.34 0.269204 5.65658 0.464466 5.85185L3.64645 9.03383ZM3.5 0.220215L3.5 8.68027L4.5 8.68027L4.5 0.220215L3.5 0.220215Z' fill='currentColor' />
          </svg>
        </span>
        <div className={cn(classes.dropdownContent, classNames.content, { open }, dropdownAlignment)} ref={dropDownRef}>
          <ul className={classes.dropdownContentList}>
            {map(options, (option, i) => <DropDownItem {...option} classNames={classNames} selected={option.value === value} key={i} onSelected={onItemSelected} />)}
          </ul>
        </div>
      </div>
      <select className={cn(classes.select, classNames.select)} value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} className={classes.selectOption} value={option.value}>{option.text}</option>
        ))}
      </select>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'relative'
  },
  select: {
    zIndex: 0,
    padding: [6, 27, 6, 12],
    width: '100%',
    fontSize: vw(16),
    height: '2.5em',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 'auto',
      visibility: 'hidden',
      fontSize: vw(16, 'lg')
    }
  },
  dropdown: {
    extend: theme.typography.bodySmall,
    position: 'absolute',
    display: 'inline-block',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
      pointerEvents: 'all'
    }
  },
  dropdownContent: {
    overflow: 'hidden',
    zIndex: 1,
    listStyle: 'none',
    backgroundColor: theme.colors.white,
    border: [1, 'solid', theme.colors.black],
    position: 'absolute',
    height: 0,
    transform: 'translateY(-1px)',
    top: 'calc(100% - 1px)',
    minWidth: '100%',
    margin: 0,
    padding: 0,
    textAlign: 'left',
    '&.left': {
      left: 0,
      transformOrigin: '0% 0%'
    },
    '&.right': {
      right: 0,
      transformOrigin: '100% 0%'
    }
  },
  dropdownContentList: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  value: {
    display: 'flex',
    zIndex: 2,
    backgroundColor: theme.colors.white,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    padding: [0, 27, 0, 12],
    border: [1, 'solid', theme.colors.secondary],
    cursor: 'pointer',
    position: 'relative',
    whiteSpace: 'nowrap'
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  arrow: {
    position: 'absolute',
    right: 14,
    transition: 'transform 0.15s ease-in-out',
    transform: 'rotate(0)',
    width: vw(8),
    height: vw(10),
    [theme.breakpoints.up('md')]: {
      width: vw(8, 'lg'),
      height: vw(10, 'lg')
    },
    '&.open': {
      transform: 'rotate(180deg)'
    }
  },
  item: {
    padding: [6, 12, 6, 12],
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.colors.primary,
      color: theme.colors.white
    },
    '&.selected': {
      fontWeight: 'bold'
    }
  }
})

import React, { useMemo } from 'react'
import cn from 'classnames'
import detectIt from 'detect-it'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import FooterNewsletterSignup from './FooterNewsletterSignup'
import SocialLink from '../SocialLink'
import Locations from '../Locations'
import Link from '../Link'
import { getFooter, getSocialLinks } from '../../redux/slices/content'
import { resolveLink } from '../../helpers/resolveLink'
import { span } from '../../style/span'
import { adjacentSide } from '../../helpers/trigonometry'
import { useDarkSlice } from '../HeaderContextProvider'
import theme, { vw } from '../../style/theme'

const MenuItem = ({ link, classes, className }) => {
  const resolvedLink = useMemo(() => resolveLink(link), [link])
  return (
    <Link className={cn(classes.link, className)} link={resolvedLink}>
      <span>{link.text}</span>
    </Link>
  )
}

function Footer () {
  const classes = useStyles()
  const {
    copyright,
    email,
    headline,
    legal_links: legalLinks,
    locations,
    phone,
    newsletter_signup_copy: newsletterCopy
  } = useSelector(getFooter)

  console.log({ copyright })

  // const menuItems = useSelector(getMenuItems)
  const socialLinks = useSelector(getSocialLinks)
  const ref = useDarkSlice()

  return (
    <footer className={classes.footer} ref={ref}>
      <div className={classes.clipArea} />
      <div className={classes.footerContent}>
        <div className={cn(classes.topContainer, classes.cols)}>
          <div className={cn(classes.topLeft, classes.col)}>
            <h3 className={classes.headline}>{headline}</h3>
            <div className={classes.newsletterWrapper}>
              <FooterNewsletterSignup title={newsletterCopy} />
            </div>
          </div>
          <div className={cn(classes.topRight, classes.col)}>
            <div className={classes.innerCols}>
              <div className={cn(classes.innerCol, classes.menuLinks)}>
                <span className={classes.footerTitle}>
                  Explore
                </span>
                {/* {menuItems && menuItems.map((link, i) => (
                  <MenuItem key={link.id} classes={classes} link={link} className={classes.menuLink} />
                ))} */}
              </div>
              <div className={cn(classes.innerCol, classes.contactLinks)}>
                <span className={classes.footerTitle}>
                  Get In Touch
                </span>
                <a className={cn(classes.link, classes.contactLink)} href={`mailto:${email}`}>
                  {email}
                </a>
                <a className={cn(classes.link, classes.contactLink)} href={`tel:${phone}`}>
                  {phone}
                </a>
                <div className={classes.socialLinks}>
                  {socialLinks && socialLinks.map((socialLink, i) => (
                    <SocialLink
                      key={socialLink.id}
                      type={socialLink.title}
                      url={socialLink.url}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cn(classes.bottomContainer, classes.cols)}>
          <div className={cn(classes.bottomLeft, classes.col)}>
            <Locations className={classes.locations} locations={locations} />
          </div>
          <div className={cn(classes.bottomRight, classes.col)}>
            <div className={cn(classes.innerCols, classes.bottomCols)}>
              <div className={classes.innerCol} />
              <div className={cn(classes.innerCol, classes.legalLinks)}>
                <span className={classes.footerTitle}>
                  Elevation
                </span>
                {legalLinks && legalLinks.map((link, i) => (
                  <MenuItem key={link.id} classes={classes} link={link} className={classes.legalLink} />
                ))}
                {copyright && (
                  <div className={classes.copyright}>
                    {copyright.split(/\n/).map(line => <span key={line}>{line}</span>)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

const useStyles = createUseStyles({
  footer: {
    marginTop: `-${adjacentSide(2.5)}vw`,
    position: 'relative',
    zIndex: theme.zIndex.footer,
    pointerEvents: 'none'
  },
  clipArea: {
    pointerEvents: 'all',
    clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
    height: `${adjacentSide(2.5)}vw`,
    backgroundColor: theme.colors.secondary,
    marginBottom: -1
  },
  footerTitle: {
    display: 'block',
    extend: theme.typography.bodySmall,
    opacity: 0.5,
    marginBottom: 10
  },
  footerContent: {
    pointerEvents: 'all',
    color: theme.colors.white,
    backgroundColor: theme.colors.secondary,
    padding: [vw(50), 0],
    [theme.breakpoints.up('md')]: {
      padding: [vw(120, 'md'), 0]
    }
  },
  topContainer: {
    margin: [0, theme.getMargin('min'), vw(50)],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md'), vw(100, 'md')]
    }
  },
  bottomContainer: {
    margin: [0, theme.getMargin('min')],
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md')]
    }
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  col: {
    display: 'block',
    marginBottom: 50,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      flex: 1,
      '&:first-child': {
        marginRight: span(2, 'md')
      },
      '&:last-child': {
        marginLeft: span(2, 'md')
      }
    }
  },
  innerCols: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  innerCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 50,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      flex: 1,
      '&:first-child': {
        marginRight: span(1, 'md')
      },
      '&:last-child': {
        marginLeft: span(1, 'md')
      }
    }
  },
  newsletterWrapper: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(430, 'lg')
    }
  },
  newsletterLabel: {
    extend: theme.typography.bodyTiny
  },
  contactLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  menuLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  link: {
    display: 'inline-block',
    extend: theme.typography.bodySmall,
    lineHeight: 1.5,
    color: theme.colors.white,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    position: 'relative',
    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      bottom: 2,
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: theme.colors.white,
      transform: 'scaleX(0)',
      transformOrigin: 'center right',
      transition: 'transform 0.3s ease'
    },
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&:hover, &.active': {
        '&:after': {
          transform: 'scaleX(1)',
          transformOrigin: 'center left'
        }
      }
    })
  },
  socialLinks: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: vw(20),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(20, 'md')
    },
    '& a': {
      marginRight: 24,
      '&:last-child': {
        marginRight: 0
      },
      opacity: 0.5,
      transition: 'opacity 0.3s ease',
      '& svg': {
        fill: theme.colors.white
      },
      ...(detectIt.primaryInput === 'touch' ? {} : {
        '&:hover, &.active': {
          opacity: 1
        }
      })
    }
  },
  headline: {
    marginBottom: 64
  },
  locations: {
    stroke: theme.colors.white
  },
  bottomCols: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      '&>div': {
        '&:first-child': {
          marginBottom: 0
        },
        '&:last-child': {
          marginBottom: 30
        }
      }
    }
  },
  copyright: {
    display: 'flex',
    flexDirection: 'column',
    extend: theme.typography.bodySmall
  },
  legalLink: {
    extend: theme.typography.bodySmall,
    marginBottom: 5
  }
}, { name: 'Footer' })

export default Footer

import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createUseStyles } from 'react-jss'
import {
  getPress,
  isPressBusy,
  getPressTotal
} from '../../redux/slices/content'
import { pressLoadMoreActionCreator } from '../../redux/slices/content/press'
import Loading from '../Loading'
import PressArticleList from './PressArticleList'
import theme from '../../style/theme'
import LoadMorePlus from '../../images/LoadMorePlus'

const PressListSlice = ({ slice }) => {
  const classes = useStyles()
  const press = useSelector(getPress) || []
  const total = useSelector(getPressTotal)
  const busy = useSelector(isPressBusy)
  const dispatch = useDispatch()

  const loadMore = useCallback(() => {
    dispatch(pressLoadMoreActionCreator())
  }, [])

  const hasMore = !press || total > press.length

  return (
    <div className={classes.content}>
      <div className={classes.list}>
        <PressArticleList title='Press Releases' articles={press} />
      </div>
      {busy && <Loading />}
      {hasMore && (
        <div className={classes.listActions}>
          <button disabled={busy} className={classes.loadMoreButton} onClick={loadMore}>
            <LoadMorePlus />
            <span>Load More</span>
          </button>
        </div>
      )}
    </div>
  )
}

const useStyles = createUseStyles({
  content: {
    padding: [30, 0, 60],
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [50, 0, 100]
    }
  },
  list: {

  },
  listActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: [0, theme.getMargin('min')],
    [theme.breakpoints.up('md')]: {
      padding: [0, theme.getMargin('md')]
    }
  },
  loadMoreButton: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 30,
      height: 30,
      marginRight: 10
    },
    '& span': {
      paddingTop: 2,
      extend: theme.typography.bodySmall
    }
  }
}, { name: 'PressListSlice' })

export default PressListSlice

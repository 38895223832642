import React, { useCallback, useState, useRef, useEffect, forwardRef, useContext } from 'react'
import gsap from 'gsap'
import compact from 'lodash/compact'
import get from 'lodash/get'
import { createUseStyles } from 'react-jss'
import { useSelector, useDispatch } from 'react-redux'
import { getPitchLink, isHomepage } from '../../redux/slices/content'
import {
  isMenuOpen,
  isDialogOpen,
  toggleMenu,
  isCurrentBreakpointMobile
} from '../../redux/slices/layout'
import { getHomeHeroInView } from '../../redux/slices/homeHero'
import { useScrollListener } from '../useSmoothScroll'
import Button from '../Button'
import Hamburger from '../Hamburger'
import StaticHeader from './StaticHeader'
import theme from '../../style/theme'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import useWindowResize from '../../hooks/useWindowResize'
import composeRefs from '../../helpers/composeRefs'
import { HeaderContext, useDarkSliceListenerCallback } from '../HeaderContextProvider'

const animateInValues = { ease: 'expo.out', y: 0, autoAlpha: 1, duration: 0.25 }
const animateOutValues = { ease: 'expo.out', y: -20, autoAlpha: 0, duration: 0.25 }

const StickyHeader = forwardRef((props, ref) => {
  const { isDialog, onCloseClick = null } = props
  const stickyNavRef = useRef()
  const pitchButtonRef = useRef()
  const hamburgerRef = useRef()
  const toggleBtnRef = useRef()
  const staticHeaderRef = useRef()
  const [sticky, setSticky] = useState(false)
  const isMenuActive = useSelector(isMenuOpen)
  const dialogOpen = useSelector(isDialogOpen)
  const isHome = useSelector(isHomepage)
  const homeHeroInView = useSelector(getHomeHeroInView)
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const dispatch = useDispatch()
  const headerBoundingRectRef = useRef()
  const pitchLink = useSelector(getPitchLink)
  const headerContextRef = useContext(HeaderContext)

  const classes = useStyles({ isHome })

  const darkSliceListenerCallback = useCallback((isMenuActive) => {
    if (stickyNavRef.current) {
      const { inViewElements } = headerContextRef.current
      const isDark = inViewElements.current.length > 0
      const foregroundColor = isDark || isMenuActive ? theme.colors.white : theme.colors.secondary
      const backgroundColor = isDark || isMenuActive ? theme.colors.secondary : theme.colors.white
      gsap.set(stickyNavRef.current.children, { color: backgroundColor, backgroundColor: foregroundColor })
      gsap.set(compact([
        get(toggleBtnRef, ['current', 'children', 1]),
        get(pitchButtonRef, ['current', 'children', 1])
      ]), { color: foregroundColor, backgroundColor: backgroundColor })
      gsap.set(hamburgerRef.current.children, { backgroundColor: foregroundColor })
    }
  }, [])

  useDarkSliceListenerCallback(useCallback(() => darkSliceListenerCallback(), []))
  useEffect(() => {
    darkSliceListenerCallback(isMenuActive)
  }, [isMenuActive])

  const toggleMenuHandler = useCallback(() => {
    dispatch(toggleMenu())
  }, [dispatch])

  useWindowResize(useDebouncedCallback(() => {
    if (stickyNavRef.current) {
      headerBoundingRectRef.current = stickyNavRef.current.getBoundingClientRect()
    }
  }, 200, []))

  useScrollListener(useCallback((offset) => {
    if (headerBoundingRectRef.current) {
      setSticky(offset.y > headerBoundingRectRef.current.height)
    }
  }, []))

  useEffect(() => {
    if (!isMobile && !isMenuActive && !isDialog) {
      if (stickyNavRef.current && sticky) {
        gsap.to(toggleBtnRef.current, animateInValues)
      } else {
        gsap.to(toggleBtnRef.current, animateOutValues)
      }
    } else {
      gsap.to(toggleBtnRef.current, animateInValues)
    }
  }, [sticky, isMobile, isDialog, isMenuActive])

  useEffect(() => {
    if (!isMobile && !isMenuActive && !isDialog) {
      if (stickyNavRef.current && sticky) {
        gsap.to(toggleBtnRef.current, animateInValues)
      } else {
        gsap.to(toggleBtnRef.current, animateOutValues)
      }
    } else {
      gsap.to(toggleBtnRef.current, animateInValues)
    }
  }, [sticky, isMobile, isDialog, isMenuActive])

  useEffect(() => {
    if (stickyNavRef.current && isDialog) {
      if (dialogOpen) {
        gsap.to(toggleBtnRef.current, {
          ...animateInValues,
          delay: 1.2
        })
      } else {
        gsap.to(toggleBtnRef.current, animateOutValues)
      }
    }
  }, [isDialog, dialogOpen])

  useEffect(() => {
    if (isHome) {
      if (staticHeaderRef.current) {
        if (homeHeroInView) {
          gsap.to(staticHeaderRef.current, animateInValues)
        } else {
          gsap.to(staticHeaderRef.current, animateOutValues)
        }
      }
    }
  }, [homeHeroInView, isHome])

  return (
    <>
      {!isDialog && (
        <StaticHeader ref={staticHeaderRef} className={classes.stickyStaticHeader} />
      )}
      <div className={classes.stickyNav} ref={composeRefs(ref, stickyNavRef)}>
        <Button
          className={classes.menuToggleBtn}
          onClick={!onCloseClick ? toggleMenuHandler : onCloseClick}
          ref={toggleBtnRef}
          aria-label={`${isMenuActive ? 'Close' : 'Open'} the navigation menu`}
        >
          <Hamburger className={classes.hamburger} open={isDialog ? true : isMenuActive} ref={hamburgerRef} />
        </Button>
        {!isDialog && pitchLink && pitchLink[0] && (
          <Button invert={isMenuActive} link={pitchLink[0]} className={classes.button} ref={pitchButtonRef}>
            <span>{pitchLink[0].text}</span>
          </Button>
        )}
      </div>
    </>
  )
})

const useStyles = createUseStyles({
  stickyStaticHeader: {
    display: ({ isHome }) => isHome ? 'flex' : 'none',
    visibility: ({ isHome }) => isHome ? 'visible' : 'hidden'
  },
  stickyNav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: theme.zIndex.stickyHeader,
    padding: [25],
    [theme.breakpoints.up('md')]: {
      padding: [25, 50]
    }
  },
  menuToggleBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    width: 44,
    padding: 0,
    border: 'none',
    visibility: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: 44,
      width: 44
    },
    '& > span': {
      position: 'absolute',
      width: '100%',
      height: '100%'
    }
  },
  hamburger: {
    fontSize: 44
  },
  button: {
    border: 'none',
    height: 44,
    padding: [0, 18],
    marginLeft: 16,
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      marginLeft: 24,
      height: 44,
      padding: [0, 20]
    },
    '& span': {
      fontSize: 20
    }
  }
}, { name: 'StickyHeader' })

export default StickyHeader

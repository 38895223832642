import React, { useMemo } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../../../ResponsiveImage'
import Link from '../../../Link'
import { resolveLink } from '../../../../helpers/resolveLink'
import theme, { vw } from '../../../../style/theme'

const Partner = (props) => {
  const {
    logo,
    link,
    layout
  } = props
  const classes = useStyles()
  const resolvedLink = useMemo(() => link ? resolveLink(link[0]) : null, [link])
  return (
    <Link className={cn(classes.partner, layout)} link={resolvedLink} nonLinkTag='div'>
      <ResponsiveImage className={cn(classes.logo, layout)} {...logo} />
    </Link>
  )
}

const useStyles = createUseStyles({
  partner: {
    display: 'block',
    margin: '0 auto',
    textDecoration: 'none',
    color: 'currentColor',
    '&.four-column-grid': {
      width: vw(125),
      height: vw(125),
      [theme.breakpoints.up('md')]: {
        width: vw(200, 'lg'),
        height: vw(200, 'lg')
      }
    },
    '&.six-column-grid': {
      width: vw(75),
      height: vw(75),
      [theme.breakpoints.up('md')]: {
        width: vw(150, 'lg'),
        height: vw(150, 'lg')
      }
    },
    '&.eight-column-grid': {
      width: vw(50),
      height: vw(50),
      [theme.breakpoints.up('md')]: {
        width: vw(100, 'lg'),
        height: vw(100, 'lg')
      }
    }
  },
  logo: {
    width: '100%',
    height: '100%',
    '& img': {
      objectFit: 'contain',
      objectPosition: 'center center',
      fontFamily: '"object-fit: contain;"'
    }
  }
}, { name: 'Partner' })

export default Partner

import React, { useContext, useCallback, useState, forwardRef, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import * as EmailValidator from 'email-validator'
import get from 'lodash/get'
import merge from 'lodash/merge'
import Color from 'color'
import forEach from 'lodash/forEach'
import { useForm } from 'react-hook-form'
import theme, { span, vw } from '../../../style/theme'
import RichText from '../../RichText'
import Button from '../../Button'
import Loading from '../../Loading'
import FileUploadField from './FileUploadField'
import { SmoothScrollContext } from '../../useSmoothScroll'
import composeRefs from '../../../helpers/composeRefs'
import DropDown from '../../DropDown'
import blobToBase64 from '../../../utils/blobToBase64'
import SendMailApi from '../../../server/helpers/sendMailApi'

const sectorOptions = [
  { text: 'Fintech', value: 'fintech' },
  { text: 'Commerce', value: 'commerce' },
  { text: 'Health', value: 'health' },
  { text: 'SaaS', value: 'saaS' },
  { text: 'EdTech', value: 'edTech' },
  { text: 'Web3/Crypto', value: 'web3/Crypto' },
  { text: 'Logistics', value: 'logistics' },
  { text: 'Others', value: 'others' }
]
const FieldSet = forwardRef(({ className, name, label, placeholder, errors, inputComponent = 'input', isDropdown = false, setValue, value, ...rest }, ref) => {
  const classes = useStyles()
  const isError = !!get(errors, [name])
  const Input = inputComponent
  const smoothScrollContext = useContext(SmoothScrollContext)
  const localRef = useRef()
  const composedRef = composeRefs(localRef, ref)

  const onFocus = useCallback((e) => {
    const scrollbar = get(smoothScrollContext, ['current', 'scrollbar'])
    if (scrollbar && localRef.current) {
      const windowHeight = window.innerHeight
      const y = localRef.current.offsetTop - scrollbar.scrollTop
      if (y < 0 || (y + localRef.current.clientHeight) > windowHeight) {
        if (y < 0) {
          scrollbar.scrollTo(0, localRef.current.offsetTop, 150)
        } else {
          scrollbar.scrollTo(0, (localRef.current.offsetTop + localRef.current.clientHeight) - windowHeight + 20, 150)
        }
      }
    }
  }, [smoothScrollContext])

  const onChange = (e) => {
    if (e.target) {
      setValue(e.target.value)
    } else {
      setValue(e.currentTarget.value)
    }
  }

  return (
    <div className={classes.fieldSet}>
      <label className={cn(classes.label, { error: isError })}>{label}</label>
      {isDropdown
        ? <DropDown className={cn(classes.input, className, { error: isError })} options={sectorOptions} onChange={onChange} onFocus={onFocus} name={name} placeholderText={placeholder} value={value} {...rest} />
        : <Input ref={composedRef} onFocus={onFocus} className={cn(classes.input, className, { error: isError })} name={name} placeholder={placeholder} {...rest} />}
      {isError && <p className={classes.error}>{get(errors, [name, 'message']) || `Please enter your ${label}`}</p>}
    </div>
  )
})

export default ({ slice }) => {
  const classes = useStyles()
  const [formBusy, setFormBusy] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)
  const [formError, setFormError] = useState(null)
  const [sectorData, setSectorData] = useState()
  const { title, copy, fields } = slice
  const {
    first_name_label: firstNameLabel,
    first_name_placeholder: firstNamePlaceholder,
    last_name_label: lastNameLabel,
    last_name_placeholder: lastNamePlaceholder,
    email_label: emailLabel,
    email_placeholder: emailPlaceholder,
    sector,
    submit_button_label: buttonLabel,
    linkedin_label: linkedinLabel,
    linkedin_placeholder: lindedinPlaceholder,
    idea_label: ideaLabel,
    ideaPlaceholder,
    company_label: companyLabel,
    company_placeholder: companyPlaceHolder,
    pitch_supported_types: pitchSupportedTypes,
    pitch_label: pitchLabel,
    pitch_placeholder: pitchPlaceholder
  } = fields
  const { register, handleSubmit, watch, errors, setError } = useForm(
    { first_name: '', last_name: '', email: '', company: '', sector: '', linkedin: '', idea: '', pitch: null }
  )

  const firstName = watch('first_name')

  const onSubmit = useCallback(async (data) => {
    if (!sectorData) {
      setError('sector', { message: 'Sector is required' })
      return false
    } else {
      const formData = new FormData() // eslint-disable-line
      forEach(data, (item, key) => {
        formData.append(key, key === 'pitch' ? item[0] : item)
      })

      if (sector) {
        formData.append('main_sector', sectorData)
      }

      setFormSuccess(false)
      setFormError(null)
      setFormBusy(true)

      const payloadForMail = {
        to: 'pitch@elevationcapital.com',
        from: 'no-reply@elevationcapital.com',
        subject: `${sectorData} / ${data.company} / ${new Date().getTime()}`,
        message: { sectorData, ...data }
      }
      const mailHandler = new SendMailApi()
      if (data.pitch) {
        await blobToBase64(data.pitch[0]).then(res => {
          payloadForMail.message.pitch = res
          mailHandler.handle(payloadForMail)
        })
      } else {
        mailHandler.handle(payloadForMail)
      }

      var response = await fetch('/api/pitch', { // eslint-disable-line
        method: 'POST',
        body: formData
      })

      if (response.ok) {
        setFormBusy(false)
        setFormSuccess(true)
      } else {
        setFormBusy(false)
        setFormSuccess(false)
        setFormError(response)
      }
    }
  }, [sector, sectorData, setError])

  return (
    <>
      <div className={classes.container}>
        {title && <RichText content={title.text} className={classes.title} />}
        {copy && <RichText content={copy.text} className={classes.copy} />}
        {formSuccess || formBusy || formError ? (
          <>
            {formBusy && <Loading />}
            {formSuccess && (
              <h5 className={classes.successMessage}>Thanks, we’ll be in touch shortly.</h5>
            )}
          </>
        ) : (
          <>
            {formError && (
              <h5 className={classes.errorMessage}>
                Sorry, we were unable to submit your pitch! Please send your pitch directly to <a href='mailto:info@elevationcapital.com'>info@elevationcapital.com</a>
              </h5>)}
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <FieldSet name='first_name' label={firstNameLabel} placeholder={firstNamePlaceholder} ref={register({ required: true })} errors={errors} />
              <div className={cn(classes.otherFields, { show: !!firstName })}>
                <FieldSet name='last_name' label={lastNameLabel} placeholder={lastNamePlaceholder} ref={register({ required: true })} errors={errors} />
                <FieldSet
                  name='email'
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  ref={register({
                    required: true,
                    validation: { message: 'Email is invalid', value: EmailValidator.validate }
                  })}
                  errors={errors}
                  type='email'
                />
                <FieldSet name='linkedin' label={linkedinLabel} placeholder={lindedinPlaceholder} ref={register} />
                <FieldSet name='company' label={companyLabel} placeholder={companyPlaceHolder} ref={register({ required: true })} />
                <FieldSet name='sector' label={`${sector} *`} placeholder={sector} isDropdown setValue={setSectorData} value={sectorData} ref={register} />
                <FieldSet
                  name='idea'
                  className={classes.textarea}
                  label={ideaLabel}
                  placeholder={ideaPlaceholder}
                  ref={register({ required: true })}
                  inputComponent='textarea'
                  errors={errors}
                  rows={6}
                  maxLength={500}
                />
                <FileUploadField
                  typesLabel={pitchSupportedTypes}
                  name='pitch'
                  label={pitchLabel}
                  labelClassName={classes.label}
                  placeholder={pitchPlaceholder}
                  errors={errors}
                  ref={register}
                  isDragEnable={false}
                />
                <Button type='submit' className={classes.button}>{buttonLabel}</Button>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  )
}

const useStyles = createUseStyles({
  container: {
    ...(merge(
      theme.margins(),
      theme.section.headerMargin(),
      theme.section.footerMargin()
    ))
  },
  title: {
    '& > h1': {
      extend: theme.typography.h2
    },
    marginBottom: vw(56),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(56, 'lg')
    }
  },
  copy: {
    marginBottom: vw(56),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(56, 'lg'),
      maxWidth: span(9, 'md')
    }
  },
  form: {
    [theme.breakpoints.up('md')]: {
      maxWidth: span(9, 'md')
    }
  },
  fieldSet: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'lg')
    }
  },
  label: {
    fontSize: vw(10),
    display: 'block',
    marginBottom: vw(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(4, 'lg'),
      fontSize: vw(10, 'lg')
    },
    '&.error': {
      color: theme.colors.error
    }
  },
  input: {
    extend: theme.typography.body,
    borderWidth: [0, 0, 1, 0],
    borderStyle: 'solid',
    borderColor: theme.colors.secondary,
    borderRadius: 0,
    padding: [vw(8), vw(4)],
    [theme.breakpoints.up('md')]: {
      padding: [vw(8, 'lg'), vw(4, 'lg')]
    },
    '&.error': {
      borderColor: theme.colors.error
    }
  },
  textarea: {
    borderWidth: 1,
    fontSize: vw(14),
    padding: [vw(6)],
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'lg'),
      padding: [vw(6, 'lg')]
    },
    '&.error': {
      borderColor: theme.colors.error
    }
  },
  otherFields: {
    display: 'none',
    '&.show': {
      display: 'block'
    }
  },
  error: {
    background: Color(theme.colors.error).alpha(0.1).string(),
    color: theme.colors.error,
    fontSize: vw(14),
    padding: [vw(6), vw(8)],
    margin: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'lg'),
      padding: [vw(6, 'lg'), vw(8, 'lg')]
    }
  },
  button: {
    minHeight: vw(42),
    [theme.breakpoints.up('md')]: {
      minHeight: vw(42, 'lg')
    }
  }
})

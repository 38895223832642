import { createSlice } from '@reduxjs/toolkit'

const configSlice = createSlice({
  name: 'config',
  initialState: {
    api: process.env.CLIENT_API_URL
  }
})

export const getApiUrl = state => process.env.API_URL || state.config.api
export const getGlobalContentApiUrl = state => getApiUrl(state) + '/global'
export const getContentApiUrl = state => getApiUrl(state) + '/content'
export const getPreviewApiUrl = state => getApiUrl(state) + '/preview'
export const getPortfoliosApiUrl = state => getApiUrl(state) + '/portfolios'
export const getPostsApiUrl = state => getApiUrl(state) + '/posts'
export const getPressApiUrl = state => getApiUrl(state) + '/press'
export const getPitchApiUrl = state => getApiUrl(state) + '/pitch'
export const getSubscriptionApiUrl = state => getApiUrl(state) + '/subscription'

export default configSlice.reducer

import React, { useMemo } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import theme, { span } from '../../../style/theme'
import { vw } from '../../../style/vw'
import get from 'lodash/get'
import Link from '../../Link'
import { resolveLink } from '../../../helpers/resolveLink'
import { quart } from '../../../style/eases'
import LinkOutIcon from '../../../images/LinkOutIcon'
import detectIt from 'detect-it'
import { formatDate } from '../../../utils/format'

export default ({ source, title, logo, link, post_date: postDate, portfolio, className }) => {
  const classes = useStyles({ logoAspect: get(logo, ['aspect'], 0) })

  const resolvedLink = useMemo(() => resolveLink(link), [link])

  return (
    <li key={title} className={cn(className, classes.listItem)}>
      <Link link={resolvedLink} className={classes.link} nonLinkTag='div'>
        <div className={classes.hoverEffect} />
        <span className={classes.postDate}>{formatDate(postDate, '. ')}</span>
        <span className={classes.title}>{get(portfolio, ['title'])}</span>
        <span className={classes.description}>{title}</span>
        <span className={classes.source}>{source}</span>
        <span className={classes.linkButton}>
          <LinkOutIcon />
        </span>
      </Link>
    </li>
  )
}

const useStyles = createUseStyles({
  listItem: {
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: [0, theme.getMargin('md')]
    }
  },
  link: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: [vw(30), theme.getMargin()],
    textDecoration: 'none',
    color: theme.colors.text,
    flexWrap: 'wrap',
    borderBottom: [1, 'solid', theme.colors.border],
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
      padding: [vw(30, 'lg'), 0],
      '&:hover': {
        '& > *': {
          opacity: 1,
          color: theme.colors.white
        }
      }
    },
    '& > *': {
      extend: theme.typography.bodySmall,
      position: 'relative',
      margin: [0, vw(6)],
      transition: `color 0.25s ${quart.inOut}`,
      [theme.breakpoints.up('md')]: {
        marginRight: [0, vw(30, 'vw')]
      }
    },
    transition: `color 0.25s ${quart.inOut}, background-color 0.25s ${quart.inOut}`
  },
  label: {
    display: 'block',
    opacity: 0.5,
    ...(detectIt.primaryInput === 'touch' ? { } : {
      '$link:hover &': {
        opacity: 1,
        color: theme.colors.white
      }
    })
  },
  title: {
    width: '100%',
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      order: 2,
      width: span(4, 'md'),
      marginBottom: 0
    }
  },
  description: {
    width: '100%',
    marginBottom: vw(8),
    order: 2,
    [theme.breakpoints.up('md')]: {
      flexShrink: 1,
      order: 3,
      width: span(8, 'md'),
      marginBottom: 0
    }
  },
  desktopOnly: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  postDate: {
    width: '100%',
    whiteSpace: 'pre-wrap',
    extend: theme.typography.bodySmall,
    order: 0,
    opacity: 0.3,
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: span(3, 'md'),
      marginLeft: 0,
      marginBottom: 0

    }
  },
  linkText: {
    display: 'inline-block',
    extend: theme.typography.bodySmall,
    marginRight: vw(8),
    paddingTop: vw(4, 'lg'),
    [theme.breakpoints.up('md')]: {
      marginRight: vw(8, 'lg')
    }
  },
  source: {
    order: 3,
    opacity: 0.3,
    flex: 1
  },
  linkButton: {
    color: theme.colors.primary,
    order: 9,
    composes: ['$desktopOnly'],
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: span(2, 'md'),
      marginRight: 0
    },
    '& svg': {
      flexShrink: 0,
      display: 'block',
      width: vw(12, 'lg'),
      height: vw(12, 'lg'),
      '& .arrow': {
        fill: 'currentColor'
      },
      '& .box': {
        stroke: 'currentColor'
      }
    }
  },
  hoverEffect: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: -2,
    margin: 0,
    backgroundColor: theme.colors.primary,
    [theme.breakpoints.up('md')]: {
      left: -theme.getMargin('md'),
      right: -theme.getMargin('md')
    },
    transform: 'translateY(100%)',
    transition: `transform 0.25s ${quart.inOut}`,
    ...(detectIt.primaryInput === 'touch' ? { } : {
      '$link:hover &': {
        transform: 'translateY(0%)'
      }
    })
  },
  hoverImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(34, 34, 34, 0.7)'
    }
  }
}, { name: 'ArticleItem' })

import React from 'react'
import { createUseStyles } from 'react-jss'
import theme, { vw } from '../../../style/theme'
import RichText from '../../RichText'
import VideoAudioPlayer from '../../VideoAudioPlayer'

export default ({ slice }) => {
  const { title, subtitle, video, audio, image } = slice
  const classes = useStyles()
  return (
    <section className={classes.section}>
      <div className={classes.content}>
        <RichText content={title.text} className={classes.title} />
        {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
      </div>
      <VideoAudioPlayer video={video} audio={audio} posterImage={image} />
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    marginBottom: 60,
    [theme.breakpoints.up('md')]: {
      marginBottom: 100
    }
  },
  contentMargin: {
    ...theme.margins('margin', x => [0, x, x])
  },
  content: {
    composes: ['$contentMargin'],
    paddingTop: 120,
    [theme.breakpoints.up('md')]: {
      paddingTop: 200
    }
  },
  title: {
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'lg')
      // maxWidth: vw(780, 'lg')
    },
    '& h1': {
      fontSize: vw(60),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(85, 'lg')
      }
    }
  },
  subtitle: {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(400, 'lg')
    }
  }
})

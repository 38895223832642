import React, { useMemo, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
// import ResponsiveImage from '../../ResponsiveImage'
import Link from '../../Link'
// import DownloadIcon from '../../../images/DownloadIcon'
import { resolveLink } from '../../../helpers/resolveLink'
import theme, { vw } from '../../../style/theme'

// const AttendeeTile = ({ photo, position, name, page }) => {
//   const classes = useStyles()
//   const alt = photo.alt || `A photo of ${name}`
//   return (
//     <div className={classes.attendeeTile}>
//       <ResponsiveImage className={classes.profilePhoto} {...photo} alt={alt} aspect={325 / 420} />
//       <span className={classes.attendeePosition}>{position}</span>
//       <h4 className={classes.attendeeName}>{name}</h4>
//     </div>
//   )
// }

const AgendaSlice = forwardRef(({ className, slice }, ref) => {
  const { title, agenda_file: attendeesFile, endLine } = slice
  const resolvedFile = useMemo(() => attendeesFile && attendeesFile.file ? resolveLink(attendeesFile.file) : null, [attendeesFile])
  const classes = useStyles()
  return (
    <section className={cn(classes.content, className)}>
      <div className={classes.a}>
        {title && <h2 className={classes.attendeesTitle}>{title}</h2>}
        {/* {resolvedFile && (
          <Link className={classes.fileDownload} link={resolvedFile} nonLinkTag='div'>
            <span className={classes.highlighted2}>Download</span>
            <span className={classes.highlighted}>2019 Agenda</span>
            <DownloadIcon className={classes.downloadIcon} />
          </Link>
        )} */}
      </div>
      <div className={classes.a}>
        <p className={classes.aaa}>Stay tuned as we update the <strong>FR22 Agenda</strong>. In the meanwhile,{' '}
          {resolvedFile && (
            <Link className={classes.fileDownload} link={resolvedFile} nonLinkTag='div'>
              {/* <span className={classes.highlighted2}>Download</span> */}
              <span className={classes.highlighted}>click here</span>
              {/* <DownloadIcon className={classes.downloadIcon} /> */}
            </Link>)} for the <strong>FR19 Agenda</strong> to know what you can expect.
        </p>
      </div>
      {endLine && <p className={classes.endLine}>{endLine}</p>}
    </section>
  )
})

const useStyles = createUseStyles({
  content: {
    // padding: [50, theme.getMargin('min')],
    // paddingBottom: 50,
    // paddingTop: 50,
    // position: 'relative',
    backgroundColor: theme.colors.secondary
    // [theme.breakpoints.up('md')]: {
    //   padding: [100, theme.getMargin('md')],
    //   paddingBottom: 60,
    //   paddingTop: 0
    // }
  },
  gridRow: {
    marginBottom: 40,
    [theme.breakpoints.up('md')]: {
      marginBottom: 60
    }
  },
  a: {
    backgroundColor: theme.colors.secondary,
    // color: theme.colors.white,
    width: '100%',
    fontSize: '1.5rem',
    margin: 'auto',
    // height: '30%',
    padding: [0, theme.getMargin('min')],
    color: 'white'
  },
  aaa: {
    // backgroundColor: theme.colors.secondary,
    // color: theme.colors.white,
    width: '100%',
    fontSize: '1.2rem',
    margin: 'auto',
    // height: '60%',
    padding: [20, 5],
    // color: 'white',
    [theme.breakpoints.up('fab')]: {
      padding: [20, theme.getMargin('min')],
      fontSize: '1rem',
      width: '70%',
      marginBottom: vw(10)
    },
    [theme.breakpoints.up('xs')]: {
      padding: [30, theme.getMargin('min')],
      fontSize: '1.2rem',
      width: '100%',
      marginBottom: vw(10)
    },
    [theme.breakpoints.up('md')]: {
      padding: [50, theme.getMargin('min')],
      fontSize: '1.1rem',
      width: '70%',
      marginBottom: vw(10)
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
      width: '60%',
      marginBottom: vw(30)
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2.8rem',
      width: '60%',
      marginBottom: vw(30)
    }
    // position: 'relative'
  },
  rowTitle: {
    color: theme.colors.textLight,
    extend: theme.typography.bodyPlus,
    textTransform: 'none',
    marginBottom: vw(25),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(25, 'lg')
    }
  },
  attendees: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: `${theme.getGutter()}px`,
    rowGap: vw(25),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      columnGap: `${theme.getGutter('md')}px`,
      rowGap: vw(50, 'lg')
    }
  },
  endLine: {
    color: theme.colors.textLight,
    extend: theme.typography.bodyPlus,
    textTransform: 'none',
    // marginTop: vw(-15),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(-5),
      marginBottom: vw(25, 'lg')
    }
  },
  attendeeTile: {
    display: 'block',
    textDecoration: 'none',
    color: theme.colors.text,
    transition: 'color 0.3s ease',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      '&:before': {
        top: `calc(-${theme.getGutter('md')}px / 2)`,
        left: `calc(-${theme.getGutter('md')}px / 2)`,
        height: `calc(100% + ${theme.getGutter('md')}px)`,
        width: `calc(100% + ${theme.getGutter('md')}px)`
      }
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginBottom: vw(40, 'md')
    }
  },
  attendeesTitle: {
    extend: theme.typography.h3
  },
  fileDownload: {
    color: 'currentColor',
    textDecoration: 'none'
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'flex-end',
    // marginBottom: 5,
    // '& span': {
    //   extend: theme.typography.bodySmall,
    //   lineHeight: 1,
    //   marginRight: 5
    // },
    // [theme.breakpoints.up('md')]: {
    //   marginBottom: 0
    // }
  },
  highlighted: {
    // fontSize: '2rem',
    color: theme.colors.primary
    // [theme.breakpoints.down('md')]: {
    //   display: 'none'
    // }
  },
  highlighted2: {
    // color: theme.colors.primary,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  downloadIcon: {
    width: 16,
    height: 16,
    marginLeft: 5,
    [theme.breakpoints.up('md')]: {
      marginLeft: 10
    }
  },
  profilePhoto: {
    marginBottom: vw(5),
    filter: 'grayscale(90%)',
    border: '0.00001px solid #9999A7',
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(20, 'lg')
    }
  },
  attendeePosition: {
    display: 'block',
    extend: theme.typography.bodySmall,
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(8, 'lg')
    }
  },
  attendeeName: {
    extend: theme.typography.bodyPlus,
    textTransform: 'capitalize',
    marginBottom: vw(20),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(20, 'lg')
    }
  }
}, { name: 'AgendaSlice' })

export default AgendaSlice

import React, { useCallback, useRef, useEffect, forwardRef, useContext } from 'react'
import detectIt from 'detect-it'
import gsap from 'gsap'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
// import { getMenuItems } from '../../redux/slices/content'
import { isMenuOpen, isDialogOpen } from '../../redux/slices/layout'
import Link from '../Link'
import Logo from '../Logo'
import { HeaderContext, useDarkSliceListenerCallback } from '../HeaderContextProvider'
// import { resolveLink } from '../../helpers/resolveLink'
import composeRefs from '../../helpers/composeRefs'
import theme from '../../style/theme'
import Button from '../Button'

// const MenuItem = ({ link }) => {
//   const classes = useStyles()
//   // const resolvedLink = useMemo(() => resolveLink(link), [link])
//   return (
//     <Link className={cn(classes.menuLink)} link={link}>
//       <span>{link.text}</span>
//       <svg className={classes.activeTriangle} viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
//         <path d='M5 0l4.33 7.5H.67L5 0z' fill='#3283FF' />
//       </svg>
//     </Link>
//   )
// }
// const menuItems = [{ id: '1', link: { ulr: 'Register', text: 'Register' } }]
const StaticHeader = forwardRef(({ isDialog, className }, ref) => {
  const headerRef = useRef()
  const logoRef = useRef()
  // const navItemsRef = useRef()
  const isMenuActive = useSelector(isMenuOpen)
  const dialogOpen = useSelector(isDialogOpen)
  // const menuItems = useSelector(getMenuItems)
  const headerContextRef = useContext(HeaderContext)
  const classes = useStyles()

  const darkSliceListenerCallback = useCallback(() => {
    if (headerRef.current) {
      const { inViewElements } = headerContextRef.current
      const isDark = inViewElements.current.length > 0
      gsap.set(headerRef.current, { color: isDark ? theme.colors.white : theme.colors.secondary })
    }
  }, [])

  useDarkSliceListenerCallback(useCallback(() => darkSliceListenerCallback(), []))
  useEffect(() => {
    darkSliceListenerCallback()
  }, [])

  useEffect(() => {
    if (headerRef.current && isDialog) {
      if (dialogOpen) {
        gsap.to(logoRef.current, {
          ease: 'expo.out',
          y: 0,
          autoAlpha: 1,
          duration: 0.25,
          delay: 1.2
        })
      } else {
        gsap.to(logoRef.current, {
          ease: 'expo.out',
          y: -20,
          autoAlpha: 0,
          duration: 0.25
        })
      }
    }
  }, [isDialog, dialogOpen])

  return (
    <header className={cn(classes.header, className)} ref={composeRefs(ref, headerRef)}>
      <Link className={classes.logoWrapper} ref={logoRef} to='/'>
        <Logo invert={isMenuActive} />
      </Link>
      <Link className={classes.registerWrapper} ref={logoRef} to='https://www.addevent.com/event/Nf13450527'>
        <Button className={classes.register}>
          Register
        </Button>
      </Link>
    </header>
  )
})

const useStyles = createUseStyles({
  header: {
    ...theme.header.height('height'),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'fixed',
    backgroundColor: 'white',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.staticHeader,
    padding: [25],
    [theme.breakpoints.up('md')]: {
      padding: [20, 50]
    }
  },
  logoWrapper: {
    display: 'block',
    width: 128,
    height: 30,
    color: 'inherit',
    [theme.breakpoints.up('md')]: {
      width: 200,
      height: 45,
      marginRight: 48
    }
  },
  registerWrapper: {
    display: 'block',
    // width: 128,
    // height: 30,
    color: 'inherit',
    [theme.breakpoints.up('md')]: {
      // width: 200,
      // height: 45,
      // marginRight: 48
    }
  },
  menuLinks: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: 6
    }
  },
  register: {
    display: 'inline-block',
    extend: theme.typography.bodySmall,
    textTransform: 'uppercase',
    // color: 'inherit',
    // backgroundColor: 'inherit',
    textDecoration: 'none',
    position: 'relative'
  },
  registered: {
    display: 'inline-block',
    extend: theme.typography.bodySmall,
    textTransform: 'uppercase',
    // color: 'inherit',
    // backgroundColor: 'inherit',
    textDecoration: 'none',
    position: 'relative'
  },
  menuLink: {
    display: 'inline-block',
    extend: theme.typography.bodySmall,
    textTransform: 'uppercase',
    color: 'inherit',
    textDecoration: 'none',
    position: 'relative',

    '& svg': {
      display: 'block',
      position: 'absolute',
      left: '50%',
      bottom: -15,
      width: 10,
      transform: 'translateX(-50%) translateY(10px)',
      opacity: 0,
      transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
      transitionDelay: '1s'
    },

    '&.selected': {
      '& svg': {
        transform: 'translateX(-50%) translateY(0)',
        opacity: 1
      }
    },

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 2,
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: 'currentColor',
      transform: 'scaleX(0)',
      transformOrigin: 'center right',
      transition: 'transform 0.3s ease'
    },
    ...(detectIt.primaryInput === 'touch' ? {} : {
      '&:hover, &.active': {
        '&:after': {
          transform: 'scaleX(1)',
          transformOrigin: 'center left'
        }
      }
    }),
    [theme.breakpoints.up('md')]: {
      marginRight: 30,
      fontSize: 12,
      '&:last-child': {
        marginRight: 0
      }
    }
  }
}, { name: 'StaticHeader' })

export default StaticHeader

import React, { useMemo, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import ResponsiveImage from '../../ResponsiveImage'
import Link from '../../Link'
import DownloadIcon from '../../../images/DownloadIcon'
import { resolveLink } from '../../../helpers/resolveLink'
import theme, { vw } from '../../../style/theme'

const AttendeeTile = ({ photo, position, name, page }) => {
  const classes = useStyles()
  const alt = photo.alt || `A photo of ${name}`
  return (
    <div className={classes.attendeeTile}>
      <ResponsiveImage className={classes.profilePhoto} {...photo} alt={alt} aspect={325 / 420} />
      <span className={classes.attendeePosition}>{position}</span>
      <h4 className={classes.attendeeName}>{name}</h4>
    </div>
  )
}

const AttendeesSlice = forwardRef(({ className, slice }, ref) => {
  const { title, attendees_file: attendeesFile, groups, endLine } = slice
  const resolvedFile = useMemo(() => attendeesFile && attendeesFile.file ? resolveLink(attendeesFile.file) : null, [attendeesFile])
  const classes = useStyles()
  return (
    <section className={cn(classes.content, className)}>
      <div className={classes.header}>
        <h2 className={classes.attendeesTitle}>{title}</h2>
        {resolvedFile && (
          <Link className={classes.fileDownload} link={resolvedFile} nonLinkTag='div'>
            <span>Download </span>
            <span className={classes.highlighted}>Attendees</span>
            <DownloadIcon className={classes.downloadIcon} />
          </Link>
        )}
      </div>
      {groups && groups.map((group) => {
        const { id, title, attendees } = group
        return (
          <div key={id} className={classes.gridRow}>
            <h2 className={classes.rowTitle}>{title}</h2>
            <div className={classes.attendees}>
              {attendees && attendees.map((teamMember) => (
                <AttendeeTile key={teamMember.id} {...teamMember} />
              ))}
            </div>
          </div>
        )
      })}
      <p className={classes.endLine}>{endLine}</p>
    </section>
  )
})

const useStyles = createUseStyles({
  content: {
    padding: [50, theme.getMargin('min')],
    paddingBottom: 50,
    paddingTop: 50,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      padding: [100, theme.getMargin('md')],
      paddingBottom: 60,
      paddingTop: 0
    }
  },
  gridRow: {
    marginBottom: 40,
    [theme.breakpoints.up('md')]: {
      marginBottom: 60
    }
  },
  rowTitle: {
    color: theme.colors.textLight,
    extend: theme.typography.bodyPlus,
    textTransform: 'none',
    marginBottom: vw(25),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(25, 'lg')
    }
  },
  attendees: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: `${theme.getGutter()}px`,
    rowGap: vw(25),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      columnGap: `${theme.getGutter('md')}px`,
      rowGap: vw(50, 'lg')
    }
  },
  endLine: {
    color: theme.colors.textLight,
    extend: theme.typography.bodyPlus,
    textTransform: 'none',
    // marginTop: vw(-15),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(-5),
      marginBottom: vw(25, 'lg')
    }
  },
  attendeeTile: {
    display: 'block',
    textDecoration: 'none',
    color: theme.colors.text,
    transition: 'color 0.3s ease',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      '&:before': {
        top: `calc(-${theme.getGutter('md')}px / 2)`,
        left: `calc(-${theme.getGutter('md')}px / 2)`,
        height: `calc(100% + ${theme.getGutter('md')}px)`,
        width: `calc(100% + ${theme.getGutter('md')}px)`
      }
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginBottom: vw(40, 'md')
    }
  },
  attendeesTitle: {
    extend: theme.typography.h3
  },
  fileDownload: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    color: 'currentColor',
    textDecoration: 'none',
    marginBottom: 5,
    '& span': {
      extend: theme.typography.bodySmall,
      lineHeight: 1,
      marginRight: 5
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  highlighted: {
    color: theme.colors.primary,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  downloadIcon: {
    width: 16,
    height: 16,
    marginLeft: 5,
    [theme.breakpoints.up('md')]: {
      marginLeft: 10
    }
  },
  profilePhoto: {
    marginBottom: vw(5),
    filter: 'grayscale(90%)',
    border: '0.00001px solid #9999A7',
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(20, 'lg')
    }
  },
  attendeePosition: {
    display: 'block',
    extend: theme.typography.bodySmall,
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(8, 'lg')
    }
  },
  attendeeName: {
    extend: theme.typography.bodyPlus,
    textTransform: 'capitalize',
    marginBottom: vw(20),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(20, 'lg')
    }
  }
}, { name: 'AttendeesSlice' })

export default AttendeesSlice

import get from 'lodash/get'
import map from 'lodash/map'
import first from 'lodash/first'
import find from 'lodash/find'
import compact from 'lodash/compact'
import defaults from 'lodash/defaults'
import { createSelector } from 'reselect'
import { getCurrentRoutePath, isPreviewRoute, getCurrentQueryString } from '../location'
import { resolveInternalLinkUrl } from '../../../helpers/resolveLink'

export const getState = state => state

export const isContentBusy = state => !!(get(state, ['content', 'page', 'busy']) || get(state, ['content', 'global', 'busy']))
export const isDialogContentBusy = state => !!(get(state, ['content', 'dialog', 'busy']) || get(state, ['content', 'global', 'busy']))
export const getContentError = state => state.content.page.error || state.content.global.error
export const getPageContent = state => get(state, ['content', 'page', 'content'])
export const isContentError404 = state => get(getContentError(state), ['status']) === 404
export const getDialogContent = state => get(state, ['content', 'dialog', 'content'])

export const getGlobalContent = state => get(state, ['content', 'global', 'content'])
export const getFooter = state => get(getGlobalContent(state), ['footer'])
export const getSocialLinks = state => get(getGlobalContent(state), ['socialLinks'])
export const isContentReadyOrError = state => !isContentBusy(state) && !!(
  (getPageContent(state) && getGlobalContent(state)) || getContentError(state)
)

export const getSiteTitle = state => get(getGlobalContent(state), ['title'])

export const getPageId = state => get(getPageContent(state), ['id'])
export const getPageSlug = state => get(getPageContent(state), ['slug'])
export const getPageType = state => get(getPageContent(state), ['type'])
export const getPageTitle = state => get(getPageContent(state), ['title'])
export const isErrorPage = state => getPageType(state) === 'errorPage'

export const getDialogPageTitle = state => get(getDialogContent(state), ['title'])

const getMetaData = (global, page, path) => {
  const defaultCanonicalUrl = `${process.env.REACT_APP_CANONICAL_URL_BASE}${path}`
  return defaults(
    {},
    get(page, ['seo'], {}),
    {
      // these are the defaults for fields that aren't set above
      metaTitle: get(page, ['title']),
      siteName: get(global, ['title']),
      ogUrl: defaultCanonicalUrl,
      canonicalUrl: defaultCanonicalUrl
    }
  )
}

export const getPageMetaData = createSelector(
  getGlobalContent,
  getPageContent,
  getCurrentRoutePath,
  getMetaData
)

export const getDialogPageMetaData = createSelector(
  getGlobalContent,
  getDialogContent,
  getCurrentRoutePath,
  getMetaData
)

export const isHomepage = createSelector(
  getPageSlug,
  (slug) => slug === 'home'
)
export const isFoundersRetreatPage = createSelector(
  getPageSlug,
  (slug) => slug === 'founders-retreat'
)

const getHeadTitle = (global, meta, title = get(meta, ['title'])) => {
  const globalTitle = get(global, ['title'])
  if (!title) {
    return globalTitle
  }
  if (!globalTitle) {
    return title
  }
  return `${title} – ${globalTitle}`
}

export const getPageHeadTitle = createSelector(
  getGlobalContent,
  getPageMetaData,
  getPageTitle,
  getHeadTitle
)

export const getDialogPageHeadTitle = createSelector(
  getGlobalContent,
  getDialogPageMetaData,
  getDialogPageTitle,
  getHeadTitle
)

const getSlices = (page) => {
  return (page && page.slices) ? map(page.slices, (slice, index) => ({
    id: `${page.id}-${index}`,
    ...slice
  })) : undefined
}

export const getPageSlices = createSelector(
  getPageContent,
  getSlices
)

export const getDialogSlices = createSelector(
  getDialogContent,
  getSlices
)

export const getPreviewMode = state => get(state, ['content', 'preview'])

export const isPreviewMode = createSelector(
  isPreviewRoute,
  getPreviewMode,
  (previewRoute, previewMode) => previewRoute || previewMode
)

export const getMenuItems = createSelector(
  getGlobalContent,
  getCurrentRoutePath,
  (global, path) => {
    const menu = get(global, ['menu'])
    if (!menu) return []
    var pathSplit = compact(path.split('/'))
    return menu.map(item => ({
      ...item,
      selected: get(item, ['reference', 'slug']) === first(pathSplit)
    }))
  }
)

export const getPortfolioSearchSlice = createSelector(
  getPageSlices,
  (slices) => find(slices, x => x.type === 'portfolio_search_slice')
)

export const getPostListSlice = createSelector(
  getPageSlices,
  (slices) => find(slices, x => x.type === 'post_list_slice')
)

export const getFeaturedPostSlice = createSelector(
  getPageSlices,
  (slices) => find(slices, x => x.type === 'featured_post_slice')
)

export const getFeaturedPostListSlice = createSelector(
  getPageSlices,
  (slices) => find(slices, x => x.type === 'featured_post_list_slice')
)

export const getPressListSlice = createSelector(
  getPageSlices,
  (slices) => find(slices, x => x.type === 'press_list_slice')
)

export const getPosts = state => get(state, ['content', 'posts', 'content', 'pages'])
export const getPostsTotal = state => get(state, ['content', 'posts', 'content', 'total'])
export const getPostsIndex = state => get(state, ['content', 'posts', 'content', 'page'])
export const isPostsBusy = state => !!(get(state, ['content', 'posts', 'busy']))
export const isPostsLoaded = state => get(state, ['content', 'posts', 'content'])

export const getPostsQueryStringArguments = (state) => {
  const qs = getCurrentQueryString(state)
  const postPage = parseInt(get(qs, ['postPage'], 0))
  const postInitiative = get(qs, ['postInitiative'])
  return { postPage, postInitiative }
}

export const getPress = state => get(state, ['content', 'press', 'content', 'pages'])
export const getPressTotal = state => get(state, ['content', 'press', 'content', 'total'])
export const getPressIndex = state => get(state, ['content', 'press', 'content', 'page'])
export const isPressBusy = state => !!(get(state, ['content', 'press', 'busy']))
export const isPressLoaded = state => get(state, ['content', 'press', 'content'])

export const getPressQueryStringArguments = (state) => {
  const qs = getCurrentQueryString(state)
  const pressPage = parseInt(get(qs, ['pressPage'], 0))
  return { pressPage }
}

export const getPortfolios = state => get(state, ['content', 'portfolios', 'content', 'pages'])
export const getPortfoliosTotal = state => get(state, ['content', 'portfolios', 'content', 'total'])
export const getPortfoliosIndex = state => get(state, ['content', 'portfolios', 'content', 'page'])
export const isPortfoliosBusy = state => !!(get(state, ['content', 'portfolios', 'busy']))
export const isPortfoliosLoaded = state => get(state, ['content', 'portfolios', 'content'])

export const isRehydrated = state => state.content.rehydrated

export const getDialogRoutes = createSelector(
  getGlobalContent,
  (global) => {
    if (!global) return []
    return global.dialog_routes.map(resolveInternalLinkUrl)
  }
)

export const getPitchLink = createSelector(
  getGlobalContent,
  global => get(global, ['pitchLink'])
)

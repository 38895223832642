import React from 'react'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import theme, { vw } from '../../../style/theme'
import PostList from '../../Summit/PostList'

export default ({ page }) => {
  const classes = useStyles()
  const relatedPosts = get(page, ['related'])

  if (isEmpty(relatedPosts)) return null

  return (
    <section className={classes.section}>
      <h5 className={classes.title}>Related</h5>
      <PostList posts={relatedPosts} type='related' />
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    width: '100%',
    padding: '0 20px',
    paddingBottom: '50px',
    [theme.breakpoints.up('md')]: {
      margin: [0, theme.getMargin('md'), vw(80, 'lg')],
      width: vw(1000, 'lg'),
      padding: '0 50px',
      marginLeft: 'auto'
    }
  },
  title: {
    marginBottom: vw(20),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(40, 'lg')
    }
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
    }
  },
  lastTile: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}, 'RelatedPostsNew')

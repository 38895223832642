import React from 'react'
import { createUseStyles } from 'react-jss'
import detectIt from 'detect-it'
// import HighlightedText from '../HighlightedText'
import theme, { vw, span } from '../../style/theme'
// import Button from '../Button'
import cn from 'classnames'
import Timer from './Timer'
// import HighlightedText from '../HighlightedText'
import RichText from '../RichText'
import isEmpty from 'lodash/isEmpty'

// import SplitWordsAnimation from '../SplitWordsAnimation'

export default ({ slice }) => {
  // const { eventDetails, detailedText, email, title, call, date, CountdownTimer, venue } = slice
  // const classes = useStyles()
  const { cta_link: ctaLink } = slice
  const { text: btnTitle, url: btnUrl } = ctaLink[0]
  const hasHero = !isEmpty(false)
  const degrees = 6.5
  const adjacentSide = Math.tan(degrees * Math.PI / 180) * 100
  const classes = useStyles({ adjacentSide, hasHero })
  // const mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const finalDAte = slice.date.split('-').reverse()
  const date = new Date(slice.date)
  const longMonth = date.toLocaleString('en-us', { month: 'long' })
  const dateWithMonth = `${finalDAte[0]} ${longMonth} ${finalDAte[2]}`
  // const finalMonth = mL[finalDAte[1] - 1]
  // const newDate = date.toLocaleDateString('en-us', { weekday: 'long', month: 'long', year: 'numeric', day: 'numeric' })
  // console.log('finalDAte 000', `${finalDAte[0]} ${longMonth} ${finalDAte[2]}`)
  // console.log('finalMonth ====', finalMonth)
  // const sectionRef = useDarkSlice()
  return (
    <section className={classes.section}>
      <div className={classes.content}>
        {/* <div className={classes.titleWrapper}>
          <h1 className={classes.heroTitle}>{title}</h1>
        </div> */}
        <div className={classes.cols}>
          <div className={cn(classes.col, classes.colLeft)}>
            <div className={classes.rows}>
              {/* <div className={classes.row}>
                <CompanyLogo className={classes.logo} logo={logo} />
              </div> */}
              <div className={classes.eventDetails}>
                <span className={`${classes.rowLabel} ${classes.eventDetails}`}>
                  {slice.eventDetails}
                </span>
                {/* <span className={classes.stage}>{venue}</span> */}
              </div>
              {slice.venue && (
                <div className={classes.row}>
                  <span className={classes.rowLabel}>Venue</span>
                  <span className={classes.stage}>{slice.venue}</span>
                </div>
              )}
              {slice.date && (
                <div className={classes.row}>
                  <span className={classes.rowLabel}>Date</span>
                  <span className={classes.stage}>{dateWithMonth}</span>
                </div>
              )}
              {slice.call && (
                <div className={classes.row}>
                  <span className={classes.rowLabel}>Call</span>
                  <span className={classes.stage}>{slice.call}</span>
                </div>
              )}
              {slice.email && (
                <div className={classes.row}>
                  <span className={classes.rowLabel}>Email</span>
                  <span className={classes.stage2}>{slice.email}</span>
                </div>
              )}
              {/* <div className={classes.row}>
                <span className={classes.rowLabel}>Connect</span>
                <div className={classes.socialLinks}>
                  {socialLinks && socialLinks.map((socialLink, i) => (
                    <SocialLink
                      key={socialLink.id}
                      type={socialLink.title}
                      url={socialLink.url}
                    />
                  ))}
                </div>
              </div> */}
              <div className={cn(classes.row, classes.bottomRow)}>
                <div className={classes.links}>
                  <a href={btnUrl} target='blank'>
                    <button className={classes.btn}>{btnTitle}</button>
                  </a>
                  {/* <Button className={classes.button} link={c}>
                    <HighlightedText content={btnTitle} />
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
          <div className={cn(classes.col, classes.colRight, classes.timer)}>
            <Timer deadline={new Date(slice.CountdownTimer)} />
            {slice.detailedText && (
              <RichText
                content={slice.detailedText.text}
                className={classes.bio}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

// const useStyles = createUseStyles({
//   content: {
//     padding: [30, theme.getMargin('min')],
//     position: 'relative',
//     display: 'flex',
//     flexDirection: 'column-reverse',
//     justifyContent: 'space-between',
//     [theme.breakpoints.up('md')]: {
//       padding: [50, 150, theme.getMargin('md')],
//       flexDirection: 'row'
//     }
//   },
//   sectionMargin: {
//     ...theme.margins('margin', x => [0, x])
//   },
//   section: {
//     composes: ['$sectionMargin'],
//     paddingTop: 120,
//     marginBottom: 60,
//     [theme.breakpoints.up('md')]: {
//       paddingTop: 200,
//       marginBottom: 100
//     }
//   },
//   headlineContainer: {
//     [theme.breakpoints.up('md')]: {
//       maxWidth: span(14, 'md')
//     }
//   },
//   title: {
//     '& *': {
//       extend: theme.typography.h2
//     }
//   },
//   table: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     width: '350px',
//     fontFamily: 'Object Sans',
//     fontSize: '14px',
//     fontWeight: 400,
//     lineHeight: '20px',
//     padding: '15px 0',
//     borderBottom: [1, 'solid', theme.colors.border]
//   },
//   eventBox: {
//     fontFamily: 'Object Sans'
//   },
//   table1: {
//     marginRight: '100px',
//     textAlign: 'left',
//     color: '#00002366',
//     letterSpacing: '0.04em'
//   },
//   table2: {
//     letterSpacing: 0,
//     textAlign: 'right'
//   },
//   table_wrap: {
//     display: 'flex'
//   },
//   border: {
//     borderBottom: '1px solid #000000'
//   },
//   call: {
//     textDecoration: 'none',
//     color: 'initial'
//   },
//   box__wrap: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     marginBottom: '50px',
//     [theme.breakpoints.up('md')]: {
//       padding: [50, 150, theme.getMargin('md')],
//       flexDirection: 'row'
//     }
//   },
//   side_box: {
//     width: '40%',
//     fontFamily: 'Object Sans',
//     fontSize: '30px',
//     fontWeight: 400,
//     lineHeight: '36px',
//     letterSpacing: '0.03em'
//   },
//   btn: {
//     width: '350px',
//     fontFamily: 'Mars Condensed',
//     textTransform: 'uppercase',
//     fontSize: '30px',
//     fontWeight: 400,
//     lineHeight: '36px',
//     letterSpacing: '0.03em',
//     backgroundColor: '#3283FF',
//     color: '#FFFFFF',
//     textAlign: 'center',
//     padding: '10px 40px'
//   },
//   detailedText: {
//     fontFamily: 'Object Sans',
//     fontSize: '16px',
//     fontWeight: 400,
//     lineHeight: '22px',
//     letterSpacing: '0em',
//     textAlign: 'left'
//   },
//   eventDetails: {
//     display: 'block',
//     color: '#2F80ED',
//     fontSize: '14px',
//     fontWeight: 700,
//     lineHeight: '12px',
//     letterSpacing: '0.1em',
//     textAlign: 'left',
//     fontFamily: 'Object Sans',
//     // extend: theme.typography.body,
//     marginBottom: vw(8),
//     [theme.breakpoints.up('md')]: {
//       marginBottom: vw(8, 'lg')
//     }
//   }
// }, { name: 'founderHero' })

const useStyles = createUseStyles({
  sectionMargin: {
    ...theme.margins('margin', x => [0, x])
  },
  section: {
    composes: ['$sectionMargin'],
    paddingTop: 60,
    marginBottom: 60,
    [theme.breakpoints.up('md')]: {
      padding: [30, 100, theme.getMargin('md')]
      // paddingTop: 200,
      // marginBottom: 80
    }
  },
  heroWrapper: {
    position: 'relative',
    paddingBottom: '130vw',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '40vw'
    }
  },
  heroImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  clipArea: {
    position: 'absolute',
    bottom: 0,
    clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
    width: '100%',
    height: ({ adjacentSide }) => `${adjacentSide}vw`,
    backgroundColor: theme.colors.secondary,
    zIndex: 1,
    marginBottom: -1
  },
  headerSpace: {
    ...theme.header.height('paddingTop')
  },
  // content: {
  //   padding: [vw(50), theme.getMargin('min')],
  //   position: 'relative',
  //   [theme.breakpoints.up('md')]: {
  //     padding: [10, span(1, 'md'), 20],
  //     margin: [0, theme.getMargin('md')]
  //   }
  // },
  titleWrapper: {
    marginBottom: 50,
    [theme.breakpoints.up('md')]: {
      marginBottom: 100
    }
  },
  heroTitle: {
    extend: theme.typography.h0
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  timer: {
    marginTop: '1rem',
    [theme.breakpoints.up('md')]: {
      marginTop: '0'
    }
  },
  bio: {
    marginTop: '1rem',
    [theme.breakpoints.up('md')]: {
      marginTop: '0'
    }
  },
  col: {
    display: 'block',
    marginBottom: 50,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      flex: 1,
      '&:first-child': {
        maxWidth: span(9, 'md'),
        marginRight: span(2, 'md')
      },
      '&:last-child': {
        maxWidth: span(9, 'md'),
        marginLeft: span(2, 'md')
      }
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
      flex: 1,
      '&:first-child': {
        maxWidth: span(9, 'md'),
        marginRight: span(3, 'md')
      },
      '&:last-child': {
        maxWidth: span(9, 'md'),
        marginLeft: span(3, 'md')
      }
    }
  },
  rows: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Object Sans',
    justifyContent: 'space-between',
    alignItems: 'center',
    // letterSpacing: 0.5,
    borderBottom: ['0.5px', 'solid', '#000023'],
    padding: [16, 0],
    '&:first-child': {
      paddingTop: 0
    },
    '&:last-child': {
      paddingBottom: 0,
      borderBottom: 'none'
    }
    // extend: theme.typography.bodySmall
  },
  rowLabel: {
    // extend: theme.typography.bodySmall,
    fontFamily: 'Object Sans'
  },
  stage: {
    // extend: theme.typography.bodySmall,
    fontFamily: 'Object Sans',
    textTransform: 'uppercase',
    fontSize: '9px',
    [theme.breakpoints.up('mobile')]: {
      fontSize: '11px'
    },
    [theme.breakpoints.up('fab')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '8px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '8px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '24px'
    }
  },
  stage2: {
    // extend: theme.typography.bodySmall,
    fontFamily: 'Object Sans',
    // textTransform: 'uppercase',
    fontSize: '9px',
    [theme.breakpoints.up('mobile')]: {
      fontSize: '11px'
    },
    [theme.breakpoints.up('fab')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '8px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '8px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '24px'
    }
  },
  btn: {
    width: '100%',
    marginTop: '50px',
    fontFamily: 'Mars Condensed',
    textTransform: 'uppercase',
    fontSize: '30px',
    fontWeight: 400,
    lineHeight: '36px',
    letterSpacing: '0.03em',
    backgroundColor: '#3283FF',
    color: '#FFFFFF',
    textAlign: 'center',
    padding: '10px 40px'
  },
  eventDetails: {
    // display: 'block',
    color: '#2F80ED',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '12px',
    letterSpacing: '0.1em',
    textAlign: 'left',
    fontFamily: 'Object Sans',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
    borderBottom: 0,
    // padding: [16, 0],
    '&:first-child': {
      paddingTop: 0
    },
    '&:last-child': {
      paddingBottom: 0,
      borderBottom: 'none'
    },
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(8, 'lg')
    }
  },
  socialLinks: {
    display: 'flex',
    '& a': {
      marginRight: 16,
      '&:last-child': {
        marginRight: 0
      },
      '& svg': {
        fill: theme.colors.white
      },
      opacity: 0.5,
      transition: 'opacity 0.3s ease',
      ...(detectIt.primaryInput === 'touch' ? {} : {
        '&:hover, &.active': {
          opacity: 1
        }
      })
    }
  },
  bottomRow: {
    padding: 0,
    flexDirection: 'column'
  },
  inception: {
    display: 'block',
    width: '100%',
    margin: [30, 0]
  },
  links: {
    width: '100%',
    display: 'flex',
    '& > *': {
      flex: 1,
      marginRight: 25,
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  elevatedArrow: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      bottom: 75,
      zIndex: 11,
      right: theme.getMargin('md'),

      '& svg': {
        display: 'block',
        height: 60,
        width: 8,
        fill: theme.colors.white,

        [theme.breakpoints.up('md')]: {
          transform: `translateX(${span(-1, 'md')})`
        }
      }
    }
  }
}, { name: 'founderHero' })

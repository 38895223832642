import { createSlice, combineReducers } from '@reduxjs/toolkit'
import preview from './preview'
import global from './global'
import page from './page'
import portfolios from './portfolios'
import posts from './posts'
import press from './press'
import dialog from './dialog'

import { rehydrated } from '../../actions'

export * from './selectors'

const rehydratedSlice = createSlice({
  name: 'content',
  initialState: false,
  extraReducers: builder =>
    builder
      .addCase(rehydrated.toString(), (state, action) => {
        return true
      })
})

export default combineReducers({
  global,
  page,
  portfolios,
  posts,
  press,
  dialog,
  preview,
  rehydrated: rehydratedSlice.reducer
})

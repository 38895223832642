import * as breakpoints from './breakpoints'
import * as fonts from './fonts'
import * as typography from './typography'
import { gridConfig } from './grid'
import { vw } from './vw'

export { vw } from './vw'
export { span } from './span'

const colors = {
  black: '#000',
  white: '#FFFFFF',
  offWhite: '#F5F0EB',
  text: '#000023',
  background: '#FFFFFF',
  primary: '#3283FF',
  secondary: '#000023',
  textLight: '#676767',
  grey: '#D2D2D2',
  border: 'rgba(0, 0, 35, 0.2)',
  error: '#EB5757'
}

const parallax = {
  ratio: 0.8,
  scale: 1.2
}

const globalStyles = {
  '@font-face': fonts.faces,
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent'
  },
  ':root': {
    '--scrollbar-width': '0px'
  },
  html: {
    lineHeight: 1.5
  },
  body: {
    // ...(
    //   process.env.NODE_ENV === 'development'
    //     ? require('./grid').generateAllGridStyles()
    //     : undefined
    // ),
    backgroundColor: colors.background,
    color: colors.text,
    height: '100vh',
    width: '100%',
    '-webkitFontSmoothing': 'antialiased',
    '-mozOsxFontSmoothing': 'grayscale',
    ...typography.baseBodyStyles
  },
  'h1, h2, h3, h4, h5, h6': {
    ...typography.baseHeadingsStyles,
    margin: 0
  },
  h1: {
    ...typography.h1
  },
  h2: {
    ...typography.h2
  },
  h3: {
    ...typography.h3
  },
  h4: {
    ...typography.h4
  },
  h5: {
    ...typography.h5
  },
  h6: {
    ...typography.h6
  },
  p: {
    margin: [0, 0, '1.5em'],
    ...typography.body
  },
  a: {
    color: colors.primary
  },
  button: {
    userSelect: 'none',
    cursor: 'pointer',
    appearance: 'none',
    outline: 'none',
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0
  },
  '.srOnly': {
    border: '0 !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
    clipPath: 'inset(50%) !important',
    height: '1px !important',
    margin: '-1px !important',
    overflow: 'hidden !important',
    padding: '0 !important',
    position: 'absolute !important',
    width: '1px !important',
    whiteSpace: 'nowrap !important'
  },
  '.clearfix::after': {
    content: '""',
    clear: 'both',
    display: 'table'
  }
}

export default {
  global: globalStyles,
  breakpoints,
  typography,
  colors,
  fonts,
  parallax,
  section: {
    footerMargin: (prop = 'marginBottom', func = (val, bp) => val) => ({
      [prop]: vw(60),
      [breakpoints.up('md')]: {
        [prop]: vw(100, 'lg')
      }
    }),
    headerMargin: (prop = 'marginTop', func = (val, bp) => val) => ({
      [prop]: vw(100),
      [breakpoints.up('md')]: {
        [prop]: vw(120, 'lg')
      }
    })
  },
  margins: (prop = 'margin', func = (val, bp) => val) => ({
    [prop]: func(gridConfig.min.margin),
    [breakpoints.up('md')]: {
      [prop]: func(gridConfig.md.margin, 'md')
    }
  }),
  getMargin: (bp = 'min') => (gridConfig[bp].margin),
  getGutter: (bp = 'min') => (gridConfig[bp].gutter),
  header: {
    height: (prop = 'height', func = val => val) => ({
      [prop]: func(95)
    })
  },
  zIndex: {
    footer: 10,
    menu: 98,
    staticHeader: 50,
    stickyHeader: 99,
    dialog: 100,
    overlay: 1000,
    pageLoadingBar: 10000
  },
  scrollbarWidthVar: 'var(--scrollbar-width)',
  link: ({
    position: 'relative',
    overflow: 'hidden',
    '&:after, &:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: colors.text,
      opacity: 0.8,
      transition: 'transform 0.5s ease-in-out'
    },
    '&:after': {
      transform: 'translateX(0%)'
    },
    '&:before': {
      transform: 'translateX(-400%)'
    },
    '&:hover': {
      '&:after': {
        transform: 'translateX(400%)'
      },
      '&:before': {
        transform: 'translateX(0%)'
      }
    }
  })
}

import React from 'react'
import { createUseStyles } from 'react-jss'
import HighlightedText from '../HighlightedText'
import theme, { vw, span } from '../../style/theme'
import SplitWordsAnimation from '../SplitWordsAnimation'

export default ({ slice }) => {
  const { title, subtitle } = slice
  const classes = useStyles()
  return (
    <section className={classes.section}>
      {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
      <div className={classes.headlineContainer}>
        <SplitWordsAnimation wrapped>
          <span className={classes.title}>
            <HighlightedText content={title.text} />
          </span>
        </SplitWordsAnimation>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  sectionMargin: {
    ...theme.margins('margin', x => [0, x])
  },
  section: {
    // composes: ['$sectionMargin'],
    // // paddingTop: 120,
    // padding: [0, 100, theme.getMargin('md')],
    // marginBottom: 30,
    // [theme.breakpoints.up('md')]: {
    //   // paddingTop: 200,
    //   marginBottom: 30
    // }
    composes: ['$sectionMargin'],
    paddingTop: 20,
    marginTop: 100,
    // marginBottom: 60,
    [theme.breakpoints.up('md')]: {
      padding: [20, 100, theme.getMargin('md')],
      paddingTop: 0,
      marginBottom: 0,
      marginTop: 100,
      paddingBottom: 0
    }
  },
  headlineContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: span(14, 'md')
    }
  },
  title: {
    '& *': {
      extend: theme.typography.h3,
      [theme.breakpoints.up('md')]: {
        extend: theme.typography.h2
        // marginBottom: vw(8, 'lg')
      }
    }
  },
  subtitle: {
    display: 'block',
    extend: theme.typography.bodySmall,
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(8, 'lg')
    }
  }
}, { name: 'HeaderTextBlock' })

import React from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import theme, { span, vw } from '../../style/theme'
import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import RichText from '../RichText'
import isEmpty from 'lodash/isEmpty'
import SocialLink, { FACEBOOK_TYPE, INSTAGRAM_TYPE, LINKEDIN_TYPE, TWITTER_TYPE } from '../SocialLink'

function getSocialLinkType (url) {
  if (url.indexOf('facebook') >= 0) {
    return FACEBOOK_TYPE
  }
  if (url.indexOf('twitter') >= 0) {
    return TWITTER_TYPE
  }
  if (url.indexOf('linkedin') >= 0) {
    return LINKEDIN_TYPE
  }
  if (url.indexOf('instagram') >= 0) {
    return INSTAGRAM_TYPE
  }
}

export default ({ slice }) => {
  const {
    team_member: teamMember
  } = slice
  const { photo, title, bio, position, socialLinks, location } = teamMember
  const hasConnection = !isEmpty(socialLinks)
  const classes = useStyles()
  return (
    <section className={classes.section}>
      <div className={classes.inner}>
        <div className={cn(classes.col, classes.colLeft)}>
          <ResponsiveImage className={classes.profilePhoto} {...photo} aspect={545 / 659} />
          {hasConnection && (
            <div className={classes.connectionsContainer}>
              <span className={classes.connectTitle}>Connect</span>
              <div className={classes.socialLinksContainer}>
                {socialLinks.map(link => {
                  const type = getSocialLinkType(link.url)
                  if (type) {
                    return (
                      <SocialLink className={classes.socialLink} key={link.url} url={link.url} type={type} />
                    )
                  }
                  return <Link className={classes.socialLink} key={link.url} to={link.url}>{link.title}</Link>
                })}
              </div>
            </div>
          )}
        </div>
        <div className={cn(classes.col, classes.colRight)}>
          <span className={classes.position}>{position}</span>
          <h1 className={classes.title}>{title}</h1>
          {location && (
            <div className={classes.locationBox}>
              <svg className={classes.locationIcon} xmlns='http://www.w3.org/2000/svg' width='11' height='22' viewBox='0 0 11 22' fill='none'>
                <path d='M1.61572 7.54356L5.50016 14L9.3846 7.54356C11.0954 4.69932 9.04525 1.08452 5.62748 1.00156C5.58486 1.00052 5.54224 1 5.49962 1C5.457 1 5.41438 1.00052 5.37176 1.00156C1.95453 1.08452 -0.0955924 4.69932 1.61572 7.54356Z' stroke='#2F80ED' stroke-miterlimit='10' stroke-linejoin='round' />
                <path d='M5.49964 7.27443C4.34653 7.27443 3.41175 6.37596 3.41175 5.26765C3.41175 4.15933 4.34653 3.26086 5.49964 3.26086C6.65275 3.26086 7.58752 4.15933 7.58752 5.26765C7.58752 6.37596 6.65275 7.27443 5.49964 7.27443Z' stroke='#2F80ED' stroke-miterlimit='10' />
              </svg>
              <div className={classes.locationName}>{location}</div>
            </div>
          )}
          {bio && <RichText content={bio.text} />}
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  sectionPadding: {
    ...theme.margins('padding')
  },
  section: {
    composes: ['$sectionPadding'],
    marginBottom: 80,
    paddingTop: vw(80),
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(180, 'lg')
    }
  },
  inner: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  colLeft: {
    marginBottom: vw(24),
    [theme.breakpoints.up('md')]: {
      width: span(10, 'md'),
      padding: [0, span(1, 'md'), 0, span(2, 'md')],
      marginBottom: 0
    }
  },
  colRight: {
    [theme.breakpoints.up('md')]: {
      width: span(14, 'md'),
      padding: [0, span(2, 'md'), 0, span(1, 'md')]
    }
  },
  profilePhoto: {},
  title: {
    extend: theme.typography.h2,
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(80, 'lg')
    }
  },
  position: {
    display: 'block',
    extend: theme.typography.bodyPlus,
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(12, 'lg')
    }
  },
  connectionsContainer: {
    extend: theme.typography.bodySmall,
    marginTop: vw(30),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: [1, 'solid', theme.colors.border],
    padding: [vw(16), 0],
    '& > span': {
      display: 'inline-block',
      [theme.breakpoints.up('md')]: {
        marginRight: vw(48, 'lg')
      }
    },
    [theme.breakpoints.up('md')]: {
      marginTop: vw(30, 'lg'),
      padding: [vw(16, 'lg'), 0]
    }
  },
  socialLinksContainer: {
    display: 'flex'
  },
  socialLink: {
    color: theme.colors.primary,
    '&:not(:last-child)': {
      marginRight: vw(24),
      [theme.breakpoints.up('md')]: {
        marginRight: vw(24, 'lg')
      }
    }
  },
  locationBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: vw(50),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(50, 'lg')
    }
  },
  locationIcon: {
    width: 12,
    height: 22,
    marginTop: 5
  },
  locationName: {
    color: theme.colors.textLight,
    textTransform: 'capitalize',
    marginLeft: 10,
    lineHeight: 1
  }
}, { name: 'TeamMemberHero' })

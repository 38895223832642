import React, { useMemo } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import { resolveLink } from '../../helpers/resolveLink'
import theme, { vw, span } from '../../style/theme'
import { formatDate } from '../../utils/format'
import PostCategory from '../Slices/Post/PostCategory'

export default (props) => {
  const {
    slug,
    title,
    link,
    post_date: postDate,
    preview_image: previewImage,
    preview_snippet: previewSnippet,
    isLandscape = false,
    isFeatured = false,
    className,
    isImageContain
  } = props
  const classes = useStyles({ isFeatured })
  const resolvedLink = useMemo(() => resolveLink(link), [link])

  return (

    <div key={slug} className={cn(className, classes.postTile, isLandscape ? 'landscape' : 'portrait')}>
      <Link link={resolvedLink} className={classes.previewImage} nonLinkTag='div'>
        <ResponsiveImage {...previewImage} aspect={isFeatured ? 1.5 : 1.4} isContain={isImageContain} />
      </Link>
      <div className={classes.postInformation}>
        <div className={classes.postMeta}>
          <span className={classes.postDate}>{formatDate(postDate, ' . ')}</span>
          <PostCategory page={props} className={classes.metaLinks} />
        </div>
        <Link link={resolvedLink} className={classes.postDescription} nonLinkTag='div'>
          <span className={classes.title}>{title}</span>
          <span className={classes.description}>{previewSnippet}</span>
        </Link>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  postTile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&.landscape': {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        '& > *': {
          '&:first-child': {
            flex: 1,
            marginBottom: 0
          },
          '&:last-child': {
            width: span(7, 'md'),
            marginLeft: span(1, 'md')
          }
        }
      }
    }
  },
  postInformation: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      paddingRight: span(1, 'md'),
      maxWidth: span(10, 'md')
    }
  },
  previewImage: {
    marginBottom: 10,
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginBottom: 35
    }
  },
  postMeta: {
    display: 'flex',
    flexDirection: 'column-reverse',
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginBottom: ({ isFeatured }) => isFeatured ? 20 : 10
    }
  },
  postDate: {
    extend: theme.typography.bodyTiny
  },
  metaLinks: {
    marginBottom: 5,
    extend: theme.typography.bodyTiny,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginLeft: 20
    }
  },
  postDescription: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: 'inherit',
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  title: {
    extend: theme.typography.bodyPlus,
    fontSize: ({ isFeatured }) => isFeatured ? vw(20) : vw(14),
    fontWeight: theme.typography.fontWeight.bold,
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      fontSize: ({ isFeatured }) => isFeatured ? vw(24, 'lg') : vw(20, 'lg'),
      marginBottom: ({ isFeatured }) => isFeatured ? 20 : 10
    }
  },
  description: {
    width: '100%',
    extend: theme.typography.bodyTiny,
    [theme.breakpoints.up('md')]: {
      extend: theme.typography.bodySmall
    }
  }
}, { name: 'PostTile' })

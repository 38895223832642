import React, { useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getPageContent, getPageId, getPageSlices, isErrorPage, isHomepage, isFoundersRetreatPage } from '../redux/slices/content'
import Footer from './Footer'
import StaticHeader from './Header/StaticHeader'
import FRHeader from './Header/FRHeader'
import StickyHeader from './Header/StickyHeader'
import Slices from './Slices'
import useSmoothScroll, { SmoothScrollContext } from './useSmoothScroll'
import ErrorPage from './ErrorPage'
import detectIt from 'detect-it'
import theme, { vw } from '../style/theme'
import { overlayOffset, overlaySkew } from '../redux/middlewares/pageTransition'
import HeaderContextProvider from './HeaderContextProvider'
import { NextPageFromState as NextPage } from './NextPage'
// import RelatedPosts from './Slices/Post/RelatedPosts'
import RelatedPostsNew from './Slices/Post/RelatedPostsNew'
import Button from './Button'
// import Button from './Button'

const Page = () => {
  const errorPage = useSelector(isErrorPage)
  const slices = useSelector(getPageSlices)
  const isHome = useSelector(isHomepage)
  const isFRetreat = useSelector(isFoundersRetreatPage)
  const page = useSelector(getPageContent)
  const id = useSelector(getPageId)
  const classes = useStyles()
  const { ref: scrollAreaRef, scrollbarRef } = useSmoothScroll({ bodyScroll: true })
  const headerRef = useRef()
  const [isLogin, setIsLogin] = useState(false)
  const [password, setPassword] = useState('')
  // const FinalPasswords = 'password'
  // let isFRetreat
  // if (typeof window !== 'undefined') {
  //   isFRetreat = window.location.pathname === '/founders-retreat'
  // }
  // console.log('isFRetreat', isFRetreat)
  // console.log('isHome', isHome)
  // useLayoutEffect(() => {
  //   if (password === FinalPasswords) {
  //     setIsLogin(true)
  //   }
  // }, [password])
  // const handleChange = (event) => {
  //   setPassword(event.target.value)
  // }
  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   console.log('password', e.target.value)
  //   if (password === FinalPasswords) {
  //     setIsLogin(true)
  //   }
  // }
  const [isIncorrect, setIsIncorrect] = useState(false)
  const final = 'ElevationFR'
  const handleChange = (event) => setPassword(event.target.value)

  function handleSubmit (event) {
    event.preventDefault()
    if (password === final) {
      setIsLogin(true)
    } else {
      setIsIncorrect(true)
    }
  }
  return (
    <HeaderContextProvider headerRef={headerRef}>
      <SmoothScrollContext.Provider value={scrollbarRef}>
        <div className={classes.transitionOverlay} id='page-overlay' />
        <div className={classes.container} id='page-container'>
          {isFRetreat ? <FRHeader ref={headerRef} /> : <StickyHeader ref={headerRef} />}
          <main className={classes.main} ref={scrollAreaRef} id='page-scroller'>
            <div className={classes.inner}>
              {(isFRetreat) ? '' : (!isHome) ? <StaticHeader /> : ''}
              {errorPage && <ErrorPage />}
              {isFRetreat
                ? isLogin
                  ? <Slices slices={slices} page={page} id={id} key={id} />
                  : (
                    <div className={classes.content}>
                      <div className={classes.formWrapper}>
                        <h4 className={classes.title}>Welcome to FR22</h4>
                        <p className={classes.subtitle}>Enter the password sent to your registered email to access the website</p>
                        <form onSubmit={handleSubmit} className={classes.input}>
                          {/* <label className={classes.label}>Enter Password</label> */}
                          <div className={classes.input2}>
                            <input type='password' value={password} onChange={handleChange} placeholder='Enter your password' />
                            <Button className={classes.btn}>Submit</Button>
                          </div>
                          {isIncorrect && password !== '' ? <div className={classes.incorrect}>Password is not correct</div> : ''}
                          {isIncorrect && password === '' && <div className={classes.incorrect}>Password should not be empty</div>}
                        </form>
                      </div>
                    </div>)
                : <Slices slices={slices} page={page} id={id} key={id} />}
              <RelatedPostsNew page={page} />
              <NextPage page={page} />
              <Footer />
            </div>
          </main>
        </div>
      </SmoothScrollContext.Provider>
    </HeaderContextProvider>
  )
}

const useStyles = createUseStyles({
  container: {},
  main: {
    width: '100%',
    position: 'relative',
    overflowX: 'hidden',
    ...(detectIt.primaryInput === 'touch' ? { } : {
      overflowY: 'auto',
      height: '100vh'
    })
  },
  inner: {
  },
  transitionOverlay: {
    zIndex: theme.zIndex.overlay,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: `calc(110vh + ${overlayOffset}vw)`,
    transform: `translateY(100%) skewY(-${overlaySkew}deg)`,
    backgroundColor: theme.colors.secondary
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backdropFilter: 'blur(10px)',
    '& input': {
      padding: '10px'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 60,
      '& input': {
        padding: '20px'
      }
      // padding: [25, theme.getMargin('md'), 200]
    }
  },
  formWrapper: {
    backgroundColor: theme.colors.secondary,
    width: '90%',
    height: '50%',
    padding: [50, theme.getMargin('min')],
    color: 'white',
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      width: '70%',
      height: '70%',
      padding: [100, theme.getMargin('md'), 200]
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
      height: '60%',
      padding: [100, theme.getMargin('md'), 200]
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      height: '60%',
      padding: [100, theme.getMargin('md'), 200]
    }

  },
  input: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    alignItems: 'start',
    backdropFilter: 'blur(10px)',
    '& level': {
      textAlign: 'left',
      fontSize: '12px'
    },
    [theme.breakpoints.up('xs')]: {
      '& level': {
        textAlign: 'left',
        fontSize: '14px'
      }
    },
    [theme.breakpoints.up('md')]: {
      '& level': {
        textAlign: 'left',
        fontSize: '16px'
      }
    }
  },
  input2: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  incorrect: {
    marginTop: '15px',
    color: 'red',
    fontSize: '13px'
  },
  title: {
    '& *': {
      fontSize: theme.typography.h2
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: '4rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '5rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '5rem'
    }
  },
  subtitle: {
    display: 'block',
    fontSize: theme.typography.bodyTiny2,
    marginBottom: vw(12),
    marginTop: vw(12),
    [theme.breakpoints.up('xs')]: {
      marginBottom: vw(20, 'xs'),
      marginTop: vw(20, 'xs'),
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(24, 'md'),
      marginTop: vw(24, 'md'),
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
      marginBottom: vw(26, 'lg'),
      marginTop: vw(26, 'lg')
    }
  }
}, { name: 'Page' })

export default Page

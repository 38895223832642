import composeReduxMiddlewares from '../../../helpers/composeReduxMiddlewares'
import raceMiddleware from './race'
import serverErrorMiddleware from './serverError'
import portfoliosMiddleware from './portfolios'
import postsMiddleware from './posts'
import pressMiddleware from './press'

export default composeReduxMiddlewares(
  raceMiddleware,
  serverErrorMiddleware,
  portfoliosMiddleware,
  postsMiddleware,
  pressMiddleware
)

import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useDropzone } from 'react-dropzone'
import cn from 'classnames'
import omit from 'lodash/omit'
import { vw } from '../../../style/vw'
import theme from '../../../style/theme'
import composeRefs from '../../../helpers/composeRefs'

export default forwardRef(({ name, label, labelClassName, typesLabel, placeholder, isDragEnable = true }, ref) => {
  const classes = useStyles()
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'application/pdf',
    maxSize: 2e+7,
    multiple: false,
    noDrag: !isDragEnable
  })

  var inputProps = getInputProps()
  var inputRef = composeRefs(ref, inputProps.ref)

  const files = acceptedFiles.map(file => (
    <li key={file.path} className={classes.files}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <div>
      <label className={labelClassName}>{label}</label>
      <div {...getRootProps({ className: 'dropzone' })} className={cn(classes.container, { isDragActive, isDragAccept, isDragReject })}>
        <input ref={inputRef} {...omit(inputProps, ['ref'])} name={name} />
        <p className={classes.placeholder}>{placeholder}</p>
        <p>{typesLabel}</p>
        {files}
      </div>
    </div>
  )
})

const useStyles = createUseStyles({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    borderWidth: 1,
    borderRadius: 0,
    borderColor: theme.colors.secondary,
    borderStyle: 'dashed',
    backgroundColor: theme.colors.offWhite,
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    minHeight: vw(100),
    marginBottom: vw(42),
    [theme.breakpoints.up('md')]: {
      minHeight: vw(100, 'lg'),
      marginBottom: vw(42, 'lg')
    },
    '& > p': {
      margin: 0,
      fontSize: vw(14),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(14, 'lg')
      }
    },
    '&.isDragActive': {
      borderColor: '#2196f3'
    },
    '&.isDragAccept': {
      borderColor: '#00AA00'
    },
    '&.isDragReject': {
      borderColor: '#AA0000'
    }
  },
  placeholder: {
    color: theme.colors.secondary
  },
  files: {
    listStyle: 'none',
    color: theme.colors.secondary,
    fontSize: vw(14),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'lg')
    }
  }
})
